import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";

import DeleteReminderModal from "./DeleteReminderModal";

export default function DeleteReminder() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        variant="outlined"
        sx={{
          width: "fit-content",
          ":hover": {
            color: "red",
            borderColor: "red",
          },
        }}
        onClick={handleOpen}
      >
        <DeleteIcon /> Delete Reminder
      </Button>
      <DeleteReminderModal onClose={handleClose} open={open} />
    </>
  );
}
