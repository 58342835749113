import React, { useState } from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { DateTime } from "luxon";

import { apiSlice } from "../util/apiSlice";
import ConfirmSubmitButton from "./ConfirmSubmitButton";
import WeekdayPicker from "./WeekdayPicker";

export default function SetRemindersForm({ day, defaultTime }) {
  const [weekday, setWeekday] = useState(day ?? "tue");

  const initialHour = defaultTime ? defaultTime.hour : 12;
  const initialMinutes = defaultTime ? defaultTime.minute : 0;
  const [time, setTime] = useState(
    DateTime.fromObject({
      hour: initialHour,
      minutes: initialMinutes,
    }),
  );

  const getSchedule = (newWeekday, newTime) => {
    if (defaultTime) {
      const { hours: diffHours, minutes: diffMins } = defaultTime
        .diff(newTime, ["days", "hours", "minutes", "seconds"])
        .toObject();
      if (newWeekday === day && diffHours === 0 && diffMins === 0) {
        return null;
      }
    }
    return `every ${newWeekday} ${newTime.toFormat("H:mm")}`;
  };
  const [schedule, setSchedule] = useState(getSchedule(weekday, time));

  const updateShcedule = (newWeekday, newTime) => {
    setSchedule(getSchedule(newWeekday, newTime));
  };
  const onWeekdayChange = (event) => {
    setWeekday(event.target.value);
    updateShcedule(event.target.value, time);
  };
  const onTimeChange = (value) => {
    setTime(value);
    updateShcedule(weekday, value);
  };

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <WeekdayPicker
          value={weekday}
          onChange={onWeekdayChange}
          sx={{
            width: 200,
          }}
        />
        <TimePicker
          value={time}
          onChange={onTimeChange}
          ampm={true}
          sx={{
            width: "auto",
          }}
        />
        <Typography variant="h6" gutterBottom>
          {time.toFormat("ZZZZ")}
        </Typography>
      </Stack>
      <ConfirmSubmitButton
        doNotResetIfGood
        postTo="/set-reminder"
        data={{ schedule, timeZone: time.zoneName }}
        disabled={!schedule}
        what="New Reminder Time"
        sx={{
          width: "fit-content",
        }}
        mutationHook={apiSlice.useSetNewDataAndReloadUserDataIfSuccessMutation}
      />
    </>
  );
}
