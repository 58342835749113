import React, { useState } from "react";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { firebaseApp } from "../util/constants";
import { B } from "./TextStyles";
import UpdateEmailModal from "./UpdateEmailModal";

export default function UpdateEmail() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Stack direction="row" spacing={3} sx={{ flexWrap: "wrap" }}>
        <Typography variant="h6">Current login email:</Typography>
        <Typography variant="h6" noWrap>
          <B>{firebaseApp.auth().currentUser.email}</B>
        </Typography>
        <Button variant="contained" onClick={handleOpen}>
          <Typography variant="button">Update</Typography>
        </Button>
      </Stack>
      <UpdateEmailModal onClose={handleClose} open={open} />
    </>
  );
}
