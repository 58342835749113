import React from "react";

import { useMediaQuery, useTheme } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { Link, matchPath, useLocation } from "react-router-dom";

import { isAdmin, isTeamConfirmed } from "../util/constants";

function TabContent(
  name,
  path,
  {
    isPublic = true,
    unconfirmedTeam = true,
    confirmedTeam = true,
    adminOnly = false,
  } = {},
) {
  return {
    name,
    path,
    public: !adminOnly && isPublic,
    unconfirmedTeam: !adminOnly && unconfirmedTeam,
    confirmedTeam: !adminOnly && confirmedTeam,
    adminOnly,
  };
}

const tabs = [
  TabContent("Welcome", "/", {
    unconfirmedTeam: false,
    confirmedTeam: false,
  }),
  TabContent("Dashboard", "/", {
    isPublic: false,
  }),
  TabContent("Pick", "/pick", {
    isPublic: false,
    unconfirmedTeam: false,
  }),
  TabContent("The Grid", "/grid"),
  TabContent("Stats", "/stats", {
    isPublic: false,
  }),
  TabContent("Roster", "/roster", {
    isPublic: false,
    unconfirmedTeam: false,
  }),
  TabContent("Rules", "/rules"),
  TabContent("Settings", "/settings", {
    isPublic: false,
  }),
  TabContent("Admin", "/admin", {
    adminOnly: true,
  }),
];

function getActiveTabs(userData) {
  const isConfirmed = isTeamConfirmed(userData);
  const admin = isAdmin();
  return tabs.filter(
    (tab) =>
      (admin && tab.adminOnly) ||
      (!userData && tab.public) ||
      (userData &&
        ((!isConfirmed && tab.unconfirmedTeam) ||
          (isConfirmed && tab.confirmedTeam))),
  );
}

function useRouteMatch(patterns) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

export default function NavTabs({ userData, onChange }) {
  const paths = getActiveTabs(userData)
    .map((tabObject) => tabObject.path)
    .sort((a, b) => b.length - a.length);
  const routeMatch = useRouteMatch(paths);
  const currentTab = routeMatch?.pattern?.path;
  const theme = useTheme();
  const isXSScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Tabs
      value={currentTab}
      indicatorColor="secondary"
      textColor="inherit"
      orientation={isXSScreen ? "vertical" : "horizontal"}
      onChange={onChange}
    >
      {getActiveTabs(userData).map((tabObject) => (
        <Tab
          key={tabObject.name}
          label={tabObject.name}
          value={tabObject.path}
          to={`${tabObject.path}?team=${encodeURIComponent(
            userData ? userData.teamName : "",
          )}`}
          component={Link}
          sx={{
            fontWeight: 600,
          }}
        />
      ))}
      {userData ? (
        <Tab
          label="NFL Schedule"
          href="http://sportsbook.fanduel.com/navigation/nfl"
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            fontWeight: 600,
          }}
        />
      ) : (
        ""
      )}
    </Tabs>
  );
}
