import React from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import { authUiConfig, firebaseApp, modalStyle } from "../util/constants";
import { B } from "./TextStyles";

export default function SignInModal({ open, onClose }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Stack>
          <Typography component="div" sx={{ alignSelf: "center" }} gutterBottom>
            <B>Choose method to sign in/up</B>
          </Typography>
          <StyledFirebaseAuth
            uiConfig={authUiConfig()}
            firebaseAuth={firebaseApp.auth()}
          />
        </Stack>
      </Box>
    </Modal>
  );
}
