import React from "react";

import Box from "@mui/material/Box";

export function A({ children, lost, ...otherProps }) {
  return (
    <Box
      display="inline"
      sx={{
        color:
          lost === undefined ? "text.accents" : `res.${lost ? "loss" : "win"}`,
      }}
      {...otherProps}
    >
      {children}
    </Box>
  );
}

export function B(props) {
  return (
    <Box display="inline" fontWeight="fontWeightBold" {...props}>
      {props.children}
    </Box>
  );
}
