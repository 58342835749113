import React from "react";

import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { LinkBehavior, weeks } from "../util/constants";
import ResultsHistory from "./ResultsHistory";
import StyledPaperCard from "./StyledPaperCard";
import TeamNameSelect from "./TeamNameSelect";

export default function DashboardTitleCard({ userData, week }) {
  let miniCards = [];
  if (!week) {
    miniCards.push(["Week", "#"]);
  } else if (week.N > 0) {
    miniCards.push(["Week", weeks[week.N - 1]]);
  }
  miniCards = [
    ...miniCards,
    ["Standing", userData.standing],
    ["Bracket", userData.lost ? "L" : "W"],
    ["Points", Math.floor(userData.totalPts)],
  ];
  return (
    <StyledPaperCard>
      <Stack spacing={3}>
        <Typography variant="h4">
          <TeamNameSelect userData={userData} /> Dashboard
        </Typography>
        <Stack direction="row" flexWrap="wrap" sx={{ gap: 1 }}>
          {miniCards.map(([title, content]) => {
            return (
              <Paper
                elevation={0}
                key={title}
                sx={{
                  backgroundColor: `res.${userData.lost ? "loss" : "win"}`,
                  color: "white",
                }}
              >
                <Stack
                  spacing={0}
                  alignItems="center"
                  sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}
                >
                  <Typography variant="subtitle2">
                    {title.toUpperCase()}
                  </Typography>
                  <Typography variant="h3">{content}</Typography>
                </Stack>
              </Paper>
            );
          })}
          <Button
            sx={{
              padding: 0,
              "&:hover": {
                backgroundColor: "#DDDDDD",
              },
            }}
            component={LinkBehavior}
            to={
              userData
                ? `/grid?team=${encodeURIComponent(userData.teamName)}`
                : "/grid"
            }
          >
            <Paper
              elevation={0}
              variant="outlined"
              sx={{
                color: `res.${userData.lost ? "loss" : "win"}`,
                borderColor: `res.${userData.lost ? "loss" : "win"}`,
                backgroundColor: "transparent",
              }}
            >
              <Stack
                spacing={0}
                alignItems="center"
                sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}
              >
                <Typography variant="subtitle2">VIEW THE</Typography>
                <Typography variant="h3">GRID</Typography>
              </Stack>
            </Paper>
          </Button>
        </Stack>
        <ResultsHistory userData={userData} week={week} />
      </Stack>
    </StyledPaperCard>
  );
}
