import React, { useEffect } from "react";

import { Outlet, useNavigate, useOutletContext } from "react-router-dom";

import { isTeamConfirmed } from "../util/constants";
import Loading from "./Loading";

export default function OldUserRoute() {
  const { userData } = useOutletContext();
  const navigate = useNavigate();
  const teamConfirmed = isTeamConfirmed(userData);
  useEffect(() => {
    if (!teamConfirmed) {
      navigate("/");
    }
  }, [navigate, teamConfirmed]);

  if (!teamConfirmed) {
    return <Loading />;
  }
  return <Outlet context={{ userData }} />;
}
