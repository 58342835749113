import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { apiSlice } from "../util/apiSlice";
import ConfirmSubmitButton from "./ConfirmSubmitButton";
import DeleteTeamModal from "./DeleteTeamModal";
import StyledPaperCard from "./StyledPaperCard";

export default function NewUsersForm({ pendingTeams }) {
  let initialState = {};
  Object.keys(pendingTeams).forEach((team) => {
    initialState[team] = { ...pendingTeams[team], hasPayed: false };
  });

  const [openDeleteModal, setDeleteModalOpen] = useState(false);
  const handleDeleteModalOpen = () => setDeleteModalOpen(true);
  const handleDeleteModalClose = () => setDeleteModalOpen(false);

  const [deleteTeamData, setDeleteTeamData] = useState({
    teamName: null,
    uids: null,
    emails: null,
  });

  const handleClickMaker = (teamName, uids, emails) => {
    return () => {
      setDeleteTeamData({
        teamName,
        uids,
        emails,
      });
      handleDeleteModalOpen();
    };
  };

  const [formState, setFormState] = useState(initialState);

  const handleChange = (event) => {
    const newState = { ...formState };
    newState[event.target.name].hasPayed = event.target.checked;
    setFormState(newState);
  };

  return (
    <StyledPaperCard>
      {!pendingTeams || Object.keys(pendingTeams).length === 0 ? (
        <Typography variant="h6" gutterBottom>
          No pending teams.
        </Typography>
      ) : (
        <Stack>
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">
              <Typography variant="h5" color="text.primary" gutterBottom>
                Select teams that have paid
              </Typography>
            </FormLabel>
            <FormGroup>
              {Object.keys(pendingTeams).map((teamName) => {
                return (
                  <Stack
                    direction="row"
                    display="inline-flex"
                    alignItems="baseline"
                    key={teamName}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState[teamName].hasPayed}
                          onChange={handleChange}
                          name={teamName}
                        />
                      }
                      label={`${teamName} - ${pendingTeams[
                        teamName
                      ].emails.join(", ")}`}
                      sx={{ width: "fit-content" }}
                    />
                    <Button
                      variant="outlined"
                      sx={{
                        pl: 1,
                        pr: 1,
                        minWidth: 0,
                        ":hover": {
                          color: "red",
                          borderColor: "red",
                        },
                      }}
                      onClick={handleClickMaker(
                        teamName,
                        pendingTeams[teamName].uids,
                        pendingTeams[teamName].emails,
                      )}
                    >
                      <DeleteIcon />
                    </Button>
                  </Stack>
                );
              })}
            </FormGroup>
          </FormControl>
          <ConfirmSubmitButton
            doNotResetIfGood
            data={formState}
            postTo="/confirm-teams"
            what="New Teams"
            sx={{ width: "fit-content" }}
            mutationHook={apiSlice.useSetNewAdminDataMutation}
          />
          <DeleteTeamModal
            onClose={handleDeleteModalClose}
            open={openDeleteModal}
            teamData={deleteTeamData}
          />
        </Stack>
      )}
    </StyledPaperCard>
  );
}
