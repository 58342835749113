import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import StyledPaperCard from "./StyledPaperCard";
import TeamNameSelect from "./TeamNameSelect";

export default function Payment({ userData }) {
  return (
    <StyledPaperCard>
      <Stack spacing={3} padding={3}>
        <Typography variant="h4" gutterBottom>
          Payment
        </Typography>
        <Typography variant="h5">
          <TeamNameSelect userData={userData} variant="h5" /> is awaiting
          payment confirmation.
        </Typography>
        <Typography variant="h6" component="div">
          <p>
            As usual, the cost of the pool is $150 for the season (including
            playoffs). You may enroll more than one team. If you want to play,
            please e-mail your intentions or call with any questions you may
            have to the number listed below.&nbsp;{" "}
            <b>Your entry must be received by Wednesday, 9/4.</b>&nbsp; Please
            include the name for your team. Ten percent of your fee will go to
            the administration of the pool, the rest to prize money.&nbsp; We
            prefer electronic payments!! We’ve had at least one check go missing
            almost every year and the USPS is not getting more reliable.
          </p>
          <p>
            <b>We prefer electronic payment using PayPal or Venmo or Zelle:</b>
          </p>
          <p>
            <b>PayPal info:</b>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <b>
              <a href="mailto:john@bkala.com">john@bkala.com</a>{" "}
            </b>
            is the PayPal account name.
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;Be sure you select:{" "}
            <b>"SEND MONEY TO FAMILY OR FRIENDS"</b>
          </p>
          <p>
            <b>Venmo info:</b>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;John Kelly
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;<b>webkelly</b> is the Venmo acct. name.
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a href="mailto:webkelly@gmail.com">webkelly@gmail.com</a>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;310-339-7500
          </p>
          <p>
            <b>Zelle info:</b>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <b>
              <a href="mailto:webkelly@gmail.com">webkelly@gmail.com</a>
            </b>
          </p>
          <p>
            <b>Important!!</b> When paying using PayPal or Venmo or Zelle,
            please include your name and your team name in the notes so you are
            certain to receive credit for your payment. A handful of you every
            year send money using PayPal but forget to use the “SEND MONEY TO
            FAMILY OR FRIENDS” feature. If you forget, I get charged for the
            transaction so don't forget! I will publish the names of the
            careless scofflaws this year as a deterrent!!
          </p>
          <p>
            If you must pay by check, send me an email (
            <b>
              <a href="mailto:jkfballpool@gmail.com">jkfballpool@gmail.com</a>
            </b>
            ) and I will send you the address to mail the check to.
          </p>
        </Typography>
      </Stack>
    </StyledPaperCard>
  );
}
