import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { apiSlice } from "../util/apiSlice";
import { firebaseApp } from "../util/constants";
import { AdminUpdateTeamPick } from "./AdminUpdateTeamPick";
import Loading from "./Loading";
import NewUsersForm from "./NewUsersForm";
import StyledPaperCard from "./StyledPaperCard";

export default function Admin() {
  const {
    data: adminData,
    isLoading,
    isError,
  } = apiSlice.useGetAdminDataQuery(firebaseApp.auth().currentUser.uid);

  return (
    <Stack spacing={2} padding={3}>
      <StyledPaperCard>
        <Typography variant="h4">Admin Panel</Typography>
      </StyledPaperCard>
      {isLoading ? (
        <Loading sx={{ height: "100vh" }} />
      ) : isError ? (
        <Typography variant="h6" gutterBottom>
          Sorry there was an error, try again later.
        </Typography>
      ) : !adminData.adminAccess ? (
        <Typography variant="h6" gutterBottom>
          Sorry you don't have access to this page.
        </Typography>
      ) : (
        <AdminContent adminData={adminData} />
      )}
    </Stack>
  );
}

function AdminContent({ adminData }) {
  return (
    <>
      <NewUsersForm pendingTeams={adminData.pendingTeams} />
      <AdminUpdateTeamPick teamsInfo={adminData.teamsInfo} />
    </>
  );
}
