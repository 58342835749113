import React, { useState } from "react";

import Button from "@mui/material/Button";

import { firebaseApp } from "../util/constants";
import SignInModal from "./SingInModal";

export default function SignInButton({ userData, sx }) {
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const handleSignInModalOpen = () => setSignInModalOpen(true);
  const handleSignInModalClose = () => setSignInModalOpen(false);

  const isWinnerColor = !userData?.lost;

  const sxDefault = {
    display: { xs: "flex", sm: "flex", md: "flex" },
    fontWeight: 600,
    backgroundColor: `res.${isWinnerColor ? "win" : "loss"}`,
    color: "white",
    ":hover": {
      backgroundColor: `res.${isWinnerColor ? "win" : "loss"}`,
      opacity: 0.5,
    },
  };

  return (
    <>
      <Button
        sx={{
          ...sxDefault,
          ...sx,
        }}
        onClick={
          userData
            ? () => {
                handleSignInModalClose();
                firebaseApp.auth().signOut();
              }
            : handleSignInModalOpen
        }
      >
        {userData ? "Sign out" : "Sign in/up"}
      </Button>
      {userData ? (
        ""
      ) : (
        <SignInModal open={signInModalOpen} onClose={handleSignInModalClose} />
      )}
    </>
  );
}
