import React, { useState } from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { apiSlice } from "../util/apiSlice";
import ConfirmSubmitButton from "./ConfirmSubmitButton";

export default function SuperBowlScore({ userData }) {
  const [score, setScore] = useState(userData.sbScore || 0);
  const userScore =
    userData && userData.sbScore ? userData.sbScore.toString() : "0";
  const max = 99.9;

  const handleScoreChange = (event) => {
    const newScore = event.target.value.replace(/^0+/, "");
    const decimals = newScore.split(".")[1];
    if (!newScore) {
      setScore(0);
    } else if (newScore > max) {
      setScore(max);
    } else if (decimals && decimals.length > 1) {
      setScore(newScore.slice(0, newScore.length - decimals.length + 1));
    } else {
      setScore(newScore);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="h5" pt={5}>
        What's the total score going to be in the Super Bowl?
      </Typography>
      <Typography gutterBottom pt={5} sx={{ paddingTop: 1 }}>
        Accuracy to the tenth of a degree will be used as tie breaker.
      </Typography>
      <TextField
        sx={{
          width: 280,
        }}
        type="number"
        inputProps={{
          step: 0.1,
          min: 0,
          max: max,
        }}
        onChange={handleScoreChange}
        value={score}
        onKeyDown={(e) => {
          if (
            e.key === "e" ||
            e.key === "E" ||
            e.key === "-" ||
            e.key === "+"
          ) {
            e.preventDefault();
          }
        }}
      />
      <ConfirmSubmitButton
        data={score}
        postTo="/update-sb-score"
        what="Super Bowl score"
        mutationHook={apiSlice.useSetNewDataAndReloadUserDataMutation}
        variant="contained"
        sx={{ width: 280, ml: 0 }}
        disabled={userData.sbScore && userScore === score}
      />
    </Box>
  );
}
