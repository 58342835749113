import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

export default function SelectTeam({ teams, onChange, selectedTeam }) {
  if (teams.length === 0) {
    return <Typography gutterBottom>There are no teams signed up</Typography>;
  }

  return (
    <Autocomplete
      sx={{
        backgroundColor: "white",
        color: "black",
        fontWeight: 700,
        width: 280,
      }}
      value={selectedTeam}
      onChange={onChange}
      options={teams.sort((a, b) => {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      })}
      groupBy={(team) => team[0].toUpperCase()}
      renderInput={(params) => <TextField {...params} label="Team" />}
    />
  );
}
