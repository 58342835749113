import React, { useMemo } from "react";

import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

import { styleCellResult, weekModes, weeks } from "../util/constants";
import FavoriteButton from "./FavoriteButton";

export default function StandingsTable({
  userData,
  week,
  standings,
  whatIfState,
  whatIf,
  showOnlyFavs,
}) {
  let formattedStandings = useMemo(() => {
    return formatStandingsForTable(standings, week);
  }, [standings, week]);
  if (formattedStandings.length > 0 && whatIf) {
    formattedStandings = formatWithWhatIf(
      formattedStandings,
      week,
      whatIfState,
    );
  }
  if (
    formattedStandings.length > 0 &&
    userData &&
    userData.favorites &&
    showOnlyFavs
  ) {
    formattedStandings = formattedStandings.filter((doc) =>
      userData.favorites.includes(doc.teamName),
    );
  }

  const columns = useMemo(() => {
    return getTableColumns(week, userData);
  }, [week, userData]);

  const table = useMaterialReactTable({
    columns,
    data: formattedStandings, //.slice(0, 20),
    enablePagination: false,
    // enableStickyHeader: true,
    // enableStickyFooter: false,
    enableGrouping: true,
    groupedColumnMode: "remove",
    enableDensityToggle: false,
    initialState: {
      density: "compact",
      sorting: [
        { id: "standing", desc: false },
        { id: "teamName", desc: false },
      ],
      isLoading: true,
    },
    layoutMode: "grid-no-grow",
    state: {
      isLoading: !(standings && week),
    },
    mrtTheme: () => ({
      baseBackgroundColor: "#FFFFFF",
    }),
    muiTablePaperProps: {
      sx: {
        borderRadius: "20px",
        boxShadow: 0,
      },
    },
    // muiTableContainerProps: { sx: { maxHeight: "75vh" } },
    muiTableBodyRowProps: ({ row }) => {
      if (userData && row.original.teamName === userData.teamName) {
        return {
          sx: { backgroundColor: "#b8b3a7" },
        };
      }
    },
    muiTableHeadCellProps: () => {
      return {
        sx: {
          borderLeft: "0.05rem solid #EDEEF0",
          borderRight: "0.05rem solid #EDEEF0",
          "& .Mui-TableHeadCell-Content": {
            justifyContent: "center",
          },
        },
      };
    },
    muiTableBodyCellProps: () => {
      return {
        sx: {
          borderLeft: "0.05rem solid #EDEEF0",
          borderRight: "0.05rem solid #EDEEF0",
          justifyContent: "center",
        },
      };
    },
  });

  return <MaterialReactTable table={table} />;
}

function getMaxWeek(week) {
  return week.N < 0 ? weeks.length + 1 : week.N;
}

function formatStandingsForTable(standings, week) {
  if (!standings || !week) {
    return [];
  }
  const maxWeek = getMaxWeek(week);
  return standings
    .map((doc) => {
      let data = { ...doc };
      for (let i = 1; i < maxWeek; i++) {
        data[`week${i}pick`] = data.prevPicks[i - 1] || "X";
        data[`week${i}res`] = data.prevRes[i - 1];
        data[`week${i}pts`] = data.prevPts[i - 1];
      }
      if (week.mode === weekModes.LOCK_ALL) {
        data[`week${maxWeek}pick`] = data.currentPick || "X";
        data[`week${maxWeek}res`] = data.currentRes || "";
        data[`week${maxWeek}pts`] = data.currentRes ? data.currentPts : "-";
        data["bracket"] = data.lost ? "L" : "W";
      } else {
        data.totalPts = data.prevPts.reduce(
          (partialSum, a) => partialSum + a,
          0,
        );
        data.standing = data.prevStandings[data.prevStandings.length - 1];
        data["bracket"] = data.lastLost ? "L" : "W";
      }
      data.totalPts = Math.floor(data.totalPts);
      data.sbScore = data.sbScore || "-";
      return data;
    })
    .sort((docA, docB) => {
      const standingDiff = docA.standing - docB.standing;
      if (standingDiff === 0) {
        const teamName1 = docA.teamName.toLowerCase();
        const teamName2 = docB.teamName.toLowerCase();
        return teamName1 > teamName2 ? 1 : teamName2 > teamName1 ? -1 : 0;
      } else {
        return standingDiff;
      }
    });
}

function formatWithWhatIf(standings, week, whatIfState) {
  const whatIfTeams = Object.keys(whatIfState);
  const maxWeek = getMaxWeek(week);
  const sortedStandings = standings
    .map((doc) => {
      let data = { ...doc };

      if (
        whatIfTeams.includes(data.currentPick) &&
        whatIfState[data.currentPick] !== "-"
      ) {
        const lost = ["L", "T"].includes(whatIfState[data.currentPick]);
        const currentPts = lost ? 0 : data.lost ? 2 : 3;
        data[`week${maxWeek}res`] = whatIfState[data.currentPick];
        data[`week${maxWeek}pts`] = currentPts;
        data["bracket"] = data.lost || lost ? "L" : "W";
        data.totalPts += currentPts;
      }

      return data;
    })
    .sort((docA, docB) => docB.totalPts - docA.totalPts);

  let lastStanding = 0;
  let lastScore = Infinity;
  for (let i = 0; i < sortedStandings.length; i++) {
    let doc = sortedStandings[i];
    let totalPts = doc.totalPts;
    if (totalPts < lastScore) {
      lastScore = totalPts;
      lastStanding = i + 1;
    }
    if (lastStanding !== doc.standing) {
      doc.standing = lastStanding;
    }
  }

  return sortedStandings;
}

function getTableColumns(week, userData) {
  let columns = [];

  // TODO: remove the isNewUser check. User should exist as soon as account is created.
  if (userData && !userData.isNewUser) {
    columns.push({
      header: "Favorite",
      accessorKey: "favorite",
      enableGrouping: false,
      size: 125,
      Cell: ({ row }) => {
        return (
          <FavoriteButton
            isFavorite={
              userData.favorites &&
              userData.favorites.includes(row.original.teamName)
            }
            teamName={row.original.teamName}
          />
        );
      },
      sortingFn: (rowA, rowB, columnId) => {
        if (!userData.favorites) return false;
        const isAFavorite = userData.favorites.includes(rowA.original.teamName);
        const isBFavorite = userData.favorites.includes(rowB.original.teamName);
        return isAFavorite === isBFavorite ? 0 : isAFavorite ? -1 : 1;
      },
    });
  }

  columns.push({
    header: "Info",
    id: "info",
    columns: [
      { header: "Standing", accessorKey: "standing", size: 155 },
      {
        header: "Team Name",
        accessorKey: "teamName",
        grouping: false,
        muiTableBodyCellProps: () => {
          return {
            sx: { whiteSpace: "nowrap" },
          };
        },
        sortingFn: (rowA, rowB, columnId) => {
          const teamNameA = rowA.original.teamName.toLowerCase();
          const teamNameB = rowB.original.teamName.toLowerCase();
          return teamNameA > teamNameB ? 1 : teamNameB > teamNameA ? -1 : 0;
        },
      },
      { header: "Total", accessorKey: "totalPts", size: 120 },
      { header: "Bracket", accessorKey: "bracket", size: 145 },
    ],
  });

  if (!week) {
    return columns;
  }

  let maxWeek = getMaxWeek(week);
  maxWeek -= week.mode === weekModes.LOCK_ALL && week.N > 0 ? 0 : 1;
  if (maxWeek === weeks.length || week.N < 0) {
    columns.push({
      header: "SB Score",
      accessorKey: "sbScore",
      size: 150,
    });
  }

  for (let i = maxWeek; i > 0; i--) {
    columns.push({
      id: `week${i}`,
      header: `Week ${weeks[i - 1]}`,
      muiTableHeadCellProps: () => {
        return {
          sx: {
            borderLeft: "0.15rem solid #EDEEF0",
            borderRight: "0.15rem solid #EDEEF0",
            "& .Mui-TableHeadCell-Content": {
              justifyContent: "center",
            },
          },
        };
      },
      columns: [
        {
          header: `Pick`,
          accessorKey: `week${i}pick`,
          size: 95,
          muiTableHeadCellProps: () => {
            return {
              sx: {
                borderLeft: "0.15rem solid #EDEEF0",
                borderRight: "0.05rem solid #EDEEF0",
                "& .Mui-TableHeadCell-Content": {
                  justifyContent: "center",
                },
              },
            };
          },
          muiTableBodyCellProps: ({ row }) => {
            return {
              sx: {
                ...styleCellResult(row.original, `week${i}res`),
                borderLeft: "0.15rem solid #EDEEF0",
                borderRight: "0.05rem solid #EDEEF0",
              },
            };
          },
        },
        // {
        //   header: `Result`,
        //   accessorKey: `week${i}res`,
        //   size: 110,
        //   muiTableBodyCellProps: ({ row }) => {
        //     return {
        //       sx: {
        //         ...styleCellResult(row.original, `week${i}res`),
        //         borderLeft: "0.05rem solid #EDEEF0",
        //         borderRight: "0.05rem solid #EDEEF0",
        //       },
        //     };
        //   },
        // },
        {
          header: `Pts`,
          accessorKey: `week${i}pts`,
          size: 95,
          muiTableHeadCellProps: () => {
            return {
              sx: {
                borderLeft: "0.05rem solid #EDEEF0",
                borderRight: "0.15rem solid #EDEEF0",
                "& .Mui-TableHeadCell-Content": {
                  justifyContent: "center",
                },
              },
            };
          },
          muiTableBodyCellProps: ({ row }) => {
            return {
              sx: {
                ...styleCellResult(row.original, `week${i}res`),
                borderLeft: "0.05rem solid #EDEEF0",
                borderRight: "0.15rem solid #EDEEF0",
              },
            };
          },
        },
      ],
    });
  }
  return columns;
}
