import React from "react";

import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import IconButton from "@mui/material/IconButton";

import { apiSlice } from "../util/apiSlice";
import { firebaseApp } from "../util/constants";

export default function FavoriteButton({ isFavorite, teamName }) {
  const [setNewData, { isLoading }] =
    apiSlice.useSetNewDataAndReloadUserDataMutation();
  const Icon = isFavorite ? StarIcon : StarBorderIcon;

  const onClick = () => {
    setNewData({
      url: "/favorite",
      body: {
        uid: firebaseApp.auth().currentUser.uid,
        data: {
          method: isFavorite ? "REMOVE" : "ADD",
          teamName,
        },
      },
    });
  };

  return (
    <IconButton
      color="inherit"
      onClick={onClick}
      disabled={isLoading}
      sx={{ padding: 0 }}
    >
      <Icon />
    </IconButton>
  );
}
