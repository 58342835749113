import React from "react";

import firebase from "firebase/compat/app";
import { Link as RouterLink } from "react-router-dom";

import "firebase/compat/auth";

import { DateTime } from "luxon";
import moize from "moize/mjs/index.mjs";

export const firebaseConfig = {
  apiKey: "AIzaSyBfkk1yieaZvyLvS0FT_KfEW4KX04STdvE",
  authDomain: "football-pool-28.firebaseapp.com",
  projectId: "football-pool-28",
  storageBucket: "football-pool-28.appspot.com",
  messagingSenderId: "271642879065",
  appId: "1:271642879065:web:86657fc27c2ed437defd48",
  measurementId: "G-4BR8YE848T",
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);

export const authUiConfig = () => {
  return {
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        fullLabel: "Continue with Google",
      },
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        fullLabel: "Continue with Email",
      },
    ],
    signInFlow: "popup",
    callbacks: {
      // TODO: add callback to call to register user.
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  };
};

export function isTeamConfirmed(userData) {
  return (
    userData &&
    !(
      userData.isNewUser ||
      userData.noAccess ||
      userData.isNewTeam ||
      userData.isJoiningTeam
    )
  );
}

export const LinkBehavior = React.forwardRef((props, ref) => (
  <RouterLink ref={ref} {...props} />
));

export function countOccurrences(array, element) {
  return array.reduce((count, e) => (e === element ? 1 : 0) + count, 0);
}

export const teamNames = {
  ARI: "Arizona Cardinals",
  ATL: "Atlanta Falcons",
  BAL: "Baltimore Ravens",
  BUF: "Buffalo Bills",
  CAR: "Carolina Panthers",
  CHI: "Chicago Bears",
  CIN: "Cincinnati Bengals",
  CLE: "Cleveland Browns",
  DAL: "Dallas Cowboys",
  DEN: "Denver Broncos",
  DET: "Detroit Lions",
  GB: "Green Bay Packers",
  HOU: "Houston Texans",
  IND: "Indianapolis Colts",
  JAX: "Jacksonville Jaguars",
  KC: "Kansas City Chiefs",
  LAC: "Los Angeles Chargers",
  LAR: "Los Angeles Rams",
  LV: "Las Vegas Raiders",
  MIA: "Miami Dolphins",
  MIN: "Minnesota Vikings",
  NE: "New England Patriots",
  NO: "New Orleans Saints",
  NYG: "New York Giants",
  NYJ: "New York Jets",
  PHI: "Philadelphia Eagles",
  PIT: "Pittsburgh Steelers",
  SEA: "Seattle Seahawks",
  SF: "San Francisco 49ers",
  TB: "Tampa Bay Buccaneers",
  TEN: "Tennessee Titans",
  WAS: "Washington Commanders",
};

export function DateTimeNow() {
  return DateTime.fromObject({}, { zone: "America/Los_Angeles" });
}

export const getByeTeams = moize((teamsStats) => {
  if (!teamsStats) {
    return null;
  }
  if (teamsStats === "error") {
    return "error";
  }
  return Object.keys(teamsStats).filter(
    (team) => teamsStats[team].opp === "BYE",
  );
});

function isPickStillAvailable(userData, teamsStats, week, pick) {
  return (
    week.mode !== weekModes.LOCK_ALL &&
    (week.mode !== weekModes.LOCK_THUR ||
      teamsStats[pick].gameDay !== "thur") &&
    !userData.usedPicks.includes(pick)
  );
}

export const getAllMatchups = moize((teamsStats, week, userData) => {
  if (!userData || !isTeamConfirmed(userData) || !teamsStats || !week) {
    return null;
  }

  return Object.keys(teamsStats)
    .filter((pick) => {
      return (
        teamsStats[pick].loc === "H" &&
        teamsStats[pick].opp &&
        teamsStats[pick].opp !== "BYE" &&
        teamsStats[pick].opp !== "DSQ"
      );
    })
    .map((pick) => {
      return {
        home: {
          pick: pick,
          available: isPickStillAvailable(userData, teamsStats, week, pick),
          odds: teamsStats[pick].odds,
        },
        away: {
          pick: teamsStats[pick].opp,
          available: isPickStillAvailable(
            userData,
            teamsStats,
            week,
            teamsStats[pick].opp,
          ),
          odds: -teamsStats[pick].odds,
        },
        startTime: DateTime.fromISO(teamsStats[pick].startTime).toLocal(),
      };
    });
});

export const getDefaultPicks = moize((teamsStats, week, userData) => {
  if (!userData || !isTeamConfirmed(userData) || !teamsStats || !week) {
    return null;
  }
  if (teamsStats === "error") {
    return "error";
  }
  return Object.keys(teamsStats)
    .filter((team) => {
      return (
        teamsStats[team].opp &&
        teamsStats[team].opp !== "BYE" &&
        teamsStats[team].opp !== "DSQ" &&
        (week.mode !== weekModes.LOCK_THUR ||
          teamsStats[team].gameDay !== "thur") &&
        !userData.usedPicks.includes(team)
      );
    })
    .sort((a, b) => {
      return teamsStats[a].odds - teamsStats[b].odds;
    })
    .slice(0, 3);
});

export const getThursdayTeams = moize((teamsStats) => {
  if (!teamsStats) {
    return [];
  }
  if (teamsStats === "error") {
    return "error";
  }
  return Object.keys(teamsStats).filter(
    (team) => teamsStats[team].gameDay === "thur",
  );
});

export const nWeeks = 18 + 4;

export const weeks = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  "WC",
  "DV",
  "CC",
  "SB",
];

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export const weekModes = {
  PICK: "PICK",
  LOCK_THUR: "LOCK_THUR",
  LOCK_ALL: "LOCK_ALL",
};

export const ADMIN_EMAIL = "jkfballpool@gmail.com";

const ADMIN_UIDS = [
  "EpUo689c04d8z7SAt1wI1rmAIhB2", // webkelly@gmail.com
  "0ljjMB6kL2hgkFprCwXJMwhU8XA3", // ejwarshawsky@gmail.com
  "CiImfwJ4RKYJdGAQHD0w9a4kKa32", // juanjouribev1@gmail.com
  "dzqvZmYh3DYEwFCspZVxSiBpJxz1", // jkfballpool@gmail.com
];

export function isAdmin() {
  return ADMIN_UIDS.includes(firebaseApp.auth().currentUser?.uid);
}

export function styleCellResult(rowData, field) {
  if (!rowData) {
    return;
  }
  const a = 0.6;
  const bgColors = {
    W: `rgba(103, 230, 105, ${a})`,
    T: `rgba(230, 103, 103, ${a})`,
    L: `rgba(230, 103, 103, ${a})`,
  };

  return {
    backgroundColor: bgColors[rowData[field]] ?? `rgba(255, 255, 255, ${a})`,
    justifyContent: "center",
  };
}

export const abrv2FullDay = {
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
  sun: "Sunday",
};
