import React, { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { apiSlice } from "../util/apiSlice";
import { modalStyle, teamNames } from "../util/constants";
import ConfirmSubmitButton from "./ConfirmSubmitButton";

export default function ConfirmTeamModal({
  open,
  onClose,
  oldTeam,
  newTeam,
  setSeverity,
}) {
  const [disabled, setDisabled] = useState(false);

  const handleClose = () => {
    setDisabled(false);
    onClose();
  };

  const handleSuccess = () => {
    setSeverity("success");
    handleClose();
  };

  const onClick = () => {
    setDisabled(true);
    return true;
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Stack sx={{ gap: 2 }}>
          <Typography variant="h5" gutterBottom>
            You are about to change a confirmed Pick
          </Typography>
          <Typography gutterBottom>
            {"Old Pick: "} {teamNames[oldTeam]}
            <br />
            {"New Pick: "} {teamNames[newTeam]}
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button variant="outlined" onClick={handleClose} sx={{ m: 2 }}>
              Cancel
            </Button>
            <ConfirmSubmitButton
              data={newTeam}
              postTo="/update-pick"
              what="Pick"
              mutationHook={apiSlice.useSetNewDataAndReloadUserDataMutation}
              disabled={disabled}
              onClick={onClick}
              variant="contained"
              runAfterSuccess={handleSuccess}
              doNotResetIfGood
            />
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
}
