import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import topBanner from "../images/welcome_banner_1.jpg";
import secondBanner from "../images/welcome_banner_2.png";
import gridPic from "../images/welcome_grid.png";
import prizesPic from "../images/welcome_prizes.png";
import storyPic from "../images/welcome_story.png";
import { LinkBehavior } from "../util/constants";
import PictureCard from "./PictureCard";
import SignInButton from "./SignInButton";
import StyledPaperCard from "./StyledPaperCard";
import { A, B } from "./TextStyles";

export default function Welcome() {
  const SignInButtonGridItem = ({ order }) => (
    <Grid
      item
      xs={12}
      md={12}
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
      order={order}
    >
      <SignInButton
        sx={{
          height: "auto",
          fontSize: "3rem",
          fontWeight: 900,
          borderRadius: "24px",
          width: { xs: "90%", sm: "max(50vw, 450px)", lg: "600px" },
        }}
      />
    </Grid>
  );

  return (
    <Grid container spacing={2} padding={3}>
      <Grid item xs={12} order={0}>
        <PictureCard
          image={topBanner}
          sx={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
          textPadding="20px"
        >
          <Typography
            variant="h1"
            sx={{ fontSize: "min(16vw, 7rem)", fontWeight: 900 }}
          >
            WELCOME TO THE POOL
          </Typography>
        </PictureCard>
      </Grid>
      <Grid item xs={12} className="noTopPadding" order={1}>
        <StyledPaperCard
          sx={{
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
          }}
        >
          <Typography variant="h6">
            <B>This isn’t your average survivor pool.</B>
            <br />
            <br />
            With an innovative format to keep players engaged all season long,
            every week matters. Every game matters. Keep reading to learn why
            this is the best football pool you’ll ever play, and why we’ve been
            going strong for 25 years.
          </Typography>
        </StyledPaperCard>
      </Grid>
      <SignInButtonGridItem order={2} />
      <Grid item xs={12} order={3}>
        <PictureCard image={secondBanner}>
          <Typography variant="h1" sx={{ fontSize: "min(13.5vw, 5.5rem)" }}>
            <B>EVERY. WEEK. MATTERS.</B>
          </Typography>
        </PictureCard>
      </Grid>
      <Grid item xs={12} order={4}>
        <StyledPaperCard>
          <Typography variant="h3" gutterBottom>
            <B>FORMAT</B>
          </Typography>
          <Typography component="div" variant="body1">
            Like a traditional survivor pool,{" "}
            <B>players select one team each week to win their game</B>. Straight
            up, no spread. <B>You can only select each team once</B> for the
            entirety of the regular season.
            <br />
            <br />
            Now here’s where we operate a little differently. Most survivor
            pools follow a “win and survive, lose and go home” format, but not
            ours. Instead, players earn points based on their selected teams'
            outcomes.{" "}
            <B>
              <A lost={false}>
                Win, keep winning, and add 3 points to your season total with
                each victory.
              </A>
            </B>{" "}
            <B>
              <A lost={true}>
                Lose or tie, and you get nothing. Not only that, but you also
                fall down to the “losers bracket,” where future wins are only
                worth 2 points.
              </A>
            </B>
            <br />
            <br />
            Because players can continue to earn points the entire season,{" "}
            <B>no one is ever counted out</B>, and playing in the pool remains
            interesting even if you take a loss.
            <br />
            <br />
            If every player drops to the losers bracket, we reset, and everyone
            is restored to the winners bracket for the following week. For the
            start of the playoffs, the same thing happens, plus all teams become
            available again for selection. For the full detailed rules, click{" "}
            <LinkBehavior to={"/rules"}>here</LinkBehavior>.
          </Typography>
        </StyledPaperCard>
      </Grid>
      <Grid item xs={12} smd={8} order={{ xs: 6, smd: 5 }}>
        <StyledPaperCard>
          <Typography variant="h3" gutterBottom>
            <B>PRIZES</B>
          </Typography>
          <Typography variant="body1" component="div">
            The entry fee to our pool is $150, and every player has the option
            of having multiple entries. At the end of the season,{" "}
            <B>
              prize money is awarded to the top 10-15% players based on total
              points.
            </B>
            <br />
            <br />
            But that’s not all.{" "}
            <B>
              Prizes are also given to the 3 players with the lowest total
              points.
            </B>{" "}
            This unique prize distribution makes it so even those who find
            themselves in the losers bracket early on still have a chance to
            win. After all, it’s just as hard to pick losers as it is to pick
            winners.
            <br />
            <br />
            In the 2023-24 season, first place received $5,000 and 22 additional
            places were paid (19 at the top, 3 at the bottom),{" "}
            <B>with a total purse of over 20 grand.</B>
          </Typography>
        </StyledPaperCard>
      </Grid>
      <Grid item xs={12} smd={4} order={{ xs: 5, smd: 6 }}>
        <PictureCard image={prizesPic}>
          <Typography variant="h1" sx={{ fontSize: "5rem", lineHeight: 0.9 }}>
            <B>MONEY</B>
          </Typography>
          <Typography
            variant="h3"
            sx={{ fontWeight: 400, textAlign: "center" }}
          >
            AT THE
          </Typography>
          <Typography variant="h3" sx={{ textAlign: "center" }}>
            <B>
              TOP <i style={{ fontWeight: 400 }}>AND</i>
            </B>
          </Typography>
          <Typography variant="h3">
            <B>BOTTOM</B>
          </Typography>
        </PictureCard>
      </Grid>
      <Grid item xs={12} smd={4} order={7}>
        <PictureCard image={gridPic}>
          <Typography variant="h2" sx={{ fontSize: "4rem", lineHeight: 0.95 }}>
            <B>ALL</B>
          </Typography>
          <Typography variant="h2" sx={{ lineHeight: 1 }}>
            <B>EYES</B>
          </Typography>
          <Typography
            variant="h4"
            sx={{ fontWeight: 400, textAlign: "center" }}
          >
            ON THE
          </Typography>
          <Typography variant="h3" sx={{ lineHeight: 1 }}>
            <B>GRID</B>
          </Typography>
        </PictureCard>
      </Grid>
      <Grid item xs={12} smd={8} order={8}>
        <StyledPaperCard>
          <Typography variant="h3" gutterBottom>
            <B>THE GRID</B>
          </Typography>
          <Typography variant="body1" component="div">
            A constant of this pool since its inception has been{" "}
            <B>the grid.</B> Every player’s team selections for every week,
            point values, and rankings, can always be viewed in this grid.
            <br />
            <br />
            Last year, we automated the grid completely, so your picks are added
            directly from the website, and it updates with live rankings within
            minutes of game finishes. See how the grid looks right now by
            clicking <LinkBehavior to={"/grid"}>here</LinkBehavior>.
          </Typography>
        </StyledPaperCard>
      </Grid>
      <Grid item xs={12} smd={8} order={{ xs: 10, smd: 9 }}>
        <StyledPaperCard>
          <Typography variant="h3" gutterBottom>
            <B>OUR STORY</B>
          </Typography>
          <Typography variant="body1" component="div">
            In 1999, this pool was created with just a handful of friends and an
            excel spreadsheet. Over time, our roster kept growing and growing,
            and the prize money grew along with it.
            <br />
            <br />
            Various updates were made. The grid changed from a vertical to a
            horizontal orientation. More players joined. A massive week one
            upset sparked an idea to add prize money at the bottom of the pool.
            More players joined. And in 2023, we said goodbye to the spreadsheet
            that had served us well for so long, and created a web app to host
            everything, from pick submissions to the grid. That’s what you’re on
            right now.
            <br />
            <br />
            <B>
              Our pool has always been a place for friends and family. We hope
              that you’ll join, and tell your friends, so our family can grow
              even bigger.
            </B>
          </Typography>
        </StyledPaperCard>
      </Grid>
      <Grid item xs={12} smd={4} order={{ xs: 9, smd: 10 }}>
        <PictureCard image={storyPic}>
          <Typography variant="h1" sx={{ fontSize: "9rem", lineHeight: 0.9 }}>
            <B>25</B>
          </Typography>
          <Typography variant="h3">
            <B>YEARS</B>
          </Typography>
          <Typography
            variant="h3"
            sx={{ fontWeight: 400, textAlign: "center" }}
          >
            OF OUR
          </Typography>
          <Typography variant="h3">
            <B>BELOVED</B>
          </Typography>
          <Typography
            variant="h3"
            sx={{ fontWeight: 400, textAlign: "center" }}
          >
            FOOTBALL POOL
          </Typography>
        </PictureCard>
      </Grid>
      <SignInButtonGridItem order={11} />
    </Grid>
  );
}
