import React from "react";

import Typography from "@mui/material/Typography";

import { weekModes } from "../util/constants";
import Rank from "./Rank";
import TeamNameSelect from "./TeamNameSelect";
import { A } from "./TextStyles";

export default function GridSubtitle({ userData, week }) {
  let standing;
  let lastStanding;
  let pts;
  let lost;
  if (week.mode === weekModes.LOCK_ALL) {
    standing = userData.standing;
    lastStanding = userData.prevStandings[userData.prevStandings.length - 1];
    pts = userData.totalPts;
    lost = userData.lost;
  } else {
    standing = userData.prevStandings[userData.prevStandings.length - 1];
    lastStanding = userData.prevStandings[userData.prevStandings.length - 2];
    pts = userData.prevPts.reduce((partialSum, a) => partialSum + a, 0);
    lost = userData.lastLost;
  }
  return (
    <Typography variant="h5" gutterBottom component="div">
      Your team, <TeamNameSelect userData={userData} variant="h5" />
      is ranked <Rank standing={standing} lastStanding={lastStanding} /> and has{" "}
      <A lost={userData.lost}>{Math.floor(pts)} points</A>, in the{" "}
      <A lost={userData.lost}>{lost ? "Losers" : "Winners"}</A> bracket.
    </Typography>
  );
}
