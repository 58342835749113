import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import { Outlet, useSearchParams } from "react-router-dom";

import { apiSlice } from "../util/apiSlice";
import { firebaseApp } from "../util/constants";
import ChatLaunchModal from "./ChatLaunchModal";
import Footer from "./Footer";
import Header from "./Header";
import Loading from "./Loading";
import ReactivateTeamModal from "./ReactivateTeamsModal";
import ScrollToTop from "./ScrollToTop";

export default function Layout() {
  const [isSignedIn, setIsSignedIn] = useState({
    isFetching: true,
    state: false,
  });
  useEffect(() => {
    const unregisterAuthObserver = firebaseApp
      .auth()
      .onAuthStateChanged((user) => {
        setIsSignedIn({
          isFetching: false,
          state: !!user,
        });
      });
    return () => unregisterAuthObserver();
  }, []);

  let [searchParams, setSearchParams] = useSearchParams();
  let paramTeamName = searchParams.get("team");
  const currentUser = firebaseApp.auth().currentUser;
  const uid = isSignedIn.state ? currentUser.uid : "";

  let {
    data: userData,
    isLoading,
    isSuccess,
    isError,
  } = apiSlice.useGetUserDataQuery(
    { uid, teamName: paramTeamName },
    { skip: !isSignedIn.state },
  );
  if (!isSignedIn.state) {
    userData = undefined;
  }

  const userDataTeamName = userData?.teamName;
  const isParamTeamNameValid =
    !userData ||
    !paramTeamName ||
    !userData.teamNames ||
    userData.teamNames.includes(paramTeamName);

  useEffect(() => {
    if (isSuccess && !isLoading && (!paramTeamName || !isParamTeamNameValid)) {
      setSearchParams({ team: userDataTeamName });
    }
  }, [
    isSuccess,
    isLoading,
    paramTeamName,
    userDataTeamName,
    isParamTeamNameValid,
    setSearchParams,
  ]);

  const [updateData, { isUninitialized }] = apiSlice.useSetNewDataMutation();

  if (isError) {
    return "There was an error";
  } else if (isSignedIn.isFetching || isLoading) {
    return <Loading sx={{ height: "100vh" }} />;
  }

  if (isUninitialized && userData && !userData.displayName) {
    updateData({
      body: {
        data: {
          displayName: firebaseApp.auth().currentUser.displayName,
        },
        uid,
        teamName: userData.teamName,
      },
      url: "/display-name",
    });
  }

  if (document.getElementsByTagName("widgetbot-crate")?.length > 0) {
    document.getElementsByTagName("widgetbot-crate").item(0).style.visibility =
      isSignedIn.state && userData.enableChat ? "visible" : "hidden";
  }

  return (
    <Box
      width="100vw"
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <ScrollToTop />
      <Header key={userData?.teamName} userData={userData} />
      {userData?.prevTeamNames?.length > 0 ? (
        <ReactivateTeamModal prevTeamNames={userData.prevTeamNames} />
      ) : (
        ""
      )}
      {/* TODO: remove isNewUser check, if user is logged in, user should exist. */}
      {userData && !userData.isNewUser && userData.enableChat === undefined ? (
        <ChatLaunchModal />
      ) : (
        ""
      )}
      <Box
        className="route"
        sx={{
          marginLeft: { md: 0, lg: "10vw" },
          marginRight: { md: 0, lg: "10vw" },
        }}
      >
        <Outlet context={{ userData }} />
      </Box>
      <Footer />
    </Box>
  );
}
