import React from "react";

import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { teamNames } from "../util/constants";

export default function TeamBanner({
  team,
  sx,
  isButton,
  disable,
  variant,
  textPadding,
  ...otherProps
}) {
  const Comp = !disable && isButton ? Button : Paper;
  const defaultSx = disable
    ? {
        backgroundColor: `text.secondary`,
      }
    : {
        backgroundColor: `team.${team}.primary`,
        maxWidth: "fit-content",
        "&:hover": {
          backgroundColor: `team.${team}.primary`,
          opacity: isButton ? 0.7 : 1,
        },
      };
  return (
    <Comp {...otherProps} sx={{ ...defaultSx, ...sx }}>
      <Typography
        variant={variant || "h4"}
        color="white"
        padding={textPadding || 2}
        align="center"
      >
        {teamNames[team].toUpperCase()}
      </Typography>
    </Comp>
  );
}
