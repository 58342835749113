import React, { useEffect } from "react";

import { Outlet, useNavigate, useOutletContext } from "react-router-dom";

import { firebaseApp } from "../util/constants";
import Loading from "./Loading";

export default function SignedInRoute() {
  const { userData } = useOutletContext();
  const navigate = useNavigate();
  const isSignedIn = !!firebaseApp.auth().currentUser;
  useEffect(() => {
    if (!isSignedIn) {
      navigate("/");
    }
  }, [navigate, isSignedIn]);

  if (!isSignedIn || !userData) {
    return <Loading />;
  }
  return <Outlet context={{ userData }} />;
}
