import React from "react";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { DateTime } from "luxon";

import { getThursdayTeams, LinkBehavior, weekModes } from "../util/constants";
import Loading from "./Loading";
import StyledPaperCard from "./StyledPaperCard";
import TeamBanner from "./TeamBanner";
import { B } from "./TextStyles";
import TimeLeft from "./TimeLeft";

export default function ThisWeekCard({
  userData,
  week,
  defaultPicks,
  teamsStats,
}) {
  if (!week) {
    return <></>;
  }
  const lockTime = DateTime.fromISO(week.lockTime);
  const lockHour = lockTime.hour;
  const lockMinutes = lockTime.minute;
  let lockWeekDay = lockTime.weekdayLong.toUpperCase();
  let lockTimeName;

  if (lockHour === 0 && lockMinutes === 0) {
    lockWeekDay = lockTime.minus({ days: 1 }).weekdayLong.toUpperCase();
    lockTimeName = "MIDNIGHT";
  } else if (lockHour === 12 && lockMinutes === 0) {
    lockTimeName = "NOON";
  }

  const thurTeams = getThursdayTeams(teamsStats);
  const isThurPick = thurTeams.includes(userData.currentPick);
  return (
    <StyledPaperCard>
      <Stack sx={{ gap: 2 }}>
        <Typography variant="h5">THIS WEEK</Typography>
        <Divider sx={{ marginBottom: 1 }} />
        <Typography component="div">
          {userData.currentPick ? (
            <>
              Your <B>{week ? week.name : "week #"}</B> pick...
            </>
          ) : (
            <>
              No <B>{week ? week.name : "week #"}</B> pick selected...
            </>
          )}
        </Typography>
        {userData.currentPick ? (
          <TeamBanner team={userData.currentPick} />
        ) : (
          <Button
            variant="contained"
            component={LinkBehavior}
            to={`/pick?team=${encodeURIComponent(userData.teamName)}`}
            fullWidth
          >
            <Typography variant="h5">Make Your Pick</Typography>
          </Button>
        )}
        {userData.sbScore ? (
          <Typography component="div">
            Your SB score prediction: <B>{userData.sbScore} pts</B>
          </Typography>
        ) : (
          ""
        )}
        <TimeLeft week={week} userData={userData} isThurPick={isThurPick} />
        {userData.currentPick ? (
          <Typography component="div">
            {(isThurPick && week.mode === weekModes.LOCK_THUR) ||
            week.mode === weekModes.LOCK_ALL ? (
              "This pick is locked."
            ) : (
              <>
                This pick locks on
                <B>
                  {isThurPick
                    ? ` THURSDAY at ${DateTime.fromObject(
                        {
                          hour: 15,
                          minute: 0,
                        },
                        { zone: "America/Los_Angeles" },
                      )
                        .toLocal()
                        .toFormat("h:mm a ZZZZ")} `
                    : ` ${lockWeekDay} at ${
                        lockTimeName ?? lockTime.toFormat("h:mm a")
                      } `}
                </B>
                during game week.
              </>
            )}
          </Typography>
        ) : (
          ""
        )}
        <Divider />
        <Typography component="div">
          {userData.currentRes ? (
            <>
              You <B>{userData.currentRes === "W" ? "Won" : "Lost"}</B>.<br />
              <br />
            </>
          ) : (
            ""
          )}
          <B>Current Available Favorites:</B>
          <br />
          <ol>
            {!defaultPicks ? (
              <Loading />
            ) : defaultPicks === "error" ? (
              <Alert severity="error" sx={{ width: "fit-content" }} />
            ) : (
              defaultPicks.map((team) => (
                <li key={team}>
                  {team} ({teamsStats[team].odds}){" "}
                  {teamsStats[team].loc === "H" ? "vs" : "@"}{" "}
                  {teamsStats[team].opp}
                </li>
              ))
            )}
          </ol>
          {thurTeams && thurTeams.length > 0 ? (
            <>
              <B>Thursday Teams:</B> {thurTeams.join(", ")}
            </>
          ) : (
            ""
          )}
        </Typography>
      </Stack>
    </StyledPaperCard>
  );
}
