import React from "react";

import ReactDOM from "react-dom";

import App from "./Components/App";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./styles/main.css";
import "@fontsource/cooper-hewitt/100.css";
import "@fontsource/cooper-hewitt/200.css";
import "@fontsource/cooper-hewitt/300.css";
import "@fontsource/cooper-hewitt/400.css";
import "@fontsource/cooper-hewitt/500.css";
import "@fontsource/cooper-hewitt/600.css";
import "@fontsource/cooper-hewitt/700.css";
import "@fontsource/cooper-hewitt/800.css";
import "@fontsource/cooper-hewitt/100-italic.css";
import "@fontsource/cooper-hewitt/200-italic.css";
import "@fontsource/cooper-hewitt/300-italic.css";
import "@fontsource/cooper-hewitt/400-italic.css";
import "@fontsource/cooper-hewitt/500-italic.css";
import "@fontsource/cooper-hewitt/600-italic.css";
import "@fontsource/cooper-hewitt/700-italic.css";
import "@fontsource/cooper-hewitt/800-italic.css";

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById("root"),
);
