import React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { apiSlice } from "../util/apiSlice";
import { ADMIN_EMAIL } from "../util/constants";

export default function Footer() {
  const { data: version, isSuccess } = apiSlice.useGetVersionQuery();

  return (
    <Box
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        paddingTop: 4,
        paddingBottom: 4,
        mt: "auto",
        py: 3,
        px: 2,
      }}
    >
      <Container maxWidth="xl">
        <Typography variant="body2" align="center">
          Copyright © The JK Fball Pool {new Date().getFullYear()}.
          <br />
          {"Contact us at "}
          <Link color="inherit" href={`mailto:${ADMIN_EMAIL}`}>
            {ADMIN_EMAIL}
          </Link>
          <br />
          {isSuccess ? `Version ${version.version}` : ""}
        </Typography>
      </Container>
    </Box>
  );
}
