import React, { useState } from "react";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { apiSlice } from "../util/apiSlice";
import { firebaseApp } from "../util/constants";
import ConfirmSubmitButton from "./ConfirmSubmitButton";

export default function CreateTeamForm({ maxLength }) {
  const [error, setError] = useState(null);
  const [teamName, setTeamName] = useState("");

  const handleChange = (event) => {
    if (event.target.value.length > maxLength) {
      setError(`Character limit: ${maxLength}`);
    } else {
      setError(null);
      setTeamName(event.target.value);
    }
  };

  const onClick = (event) => {
    event.preventDefault();
    if (!teamName) {
      setError("Team Name cannot be empty!");
      return false;
    }
    return true;
  };

  return (
    <Stack>
      <Typography variant="h4" gutterBottom>
        Create New Team
      </Typography>
      <Typography variant="h6" gutterBottom>
        Select Team Name
      </Typography>
      <TextField
        sx={{ width: "fit-content" }}
        label="Team Name"
        variant="outlined"
        inputProps={{ maxLength: maxLength }}
        error={error}
        helperText={error}
        onChange={handleChange}
        value={teamName}
      />
      <Typography gutterBottom>
        This is final. You won't be able to change the name!
      </Typography>
      <ConfirmSubmitButton
        doNotResetIfGood
        data={{
          teamName: teamName,
          email: firebaseApp.auth().currentUser.email,
        }}
        postTo="/create-team"
        what="Team Name"
        onClick={onClick}
        disabled={!!error}
        sx={{ width: "fit-content" }}
        mutationHook={apiSlice.useSetNewDataAndReloadUserDataIfSuccessMutation}
      />
    </Stack>
  );
}
