import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { DateTime } from "luxon";

import { abrv2FullDay } from "../util/constants";
import DeleteReminder from "./DeleteReminder";
import SetRemindersForm from "./SetRemindersForm";
import StyledPaperCard from "./StyledPaperCard";

export default function SetReminders({ userData }) {
  let rDay;
  let defaultTime;
  if (userData.reminderSchedule) {
    const reminderSchedule = userData.reminderSchedule.split(" ");
    rDay = reminderSchedule[1];
    const rTimeArray = reminderSchedule[2].split(":");
    defaultTime = DateTime.fromObject(
      {
        hour: rTimeArray[0],
        minute: rTimeArray[1],
      },
      { zone: userData.reminderTimeZone },
    ).toLocal();
  }

  return (
    <StyledPaperCard>
      <Stack spacing={2}>
        <Typography variant="h4">Set Weekly Reminders</Typography>
        {userData.reminderSchedule ? (
          <>
            <Typography variant="h6" gutterBottom>
              You currently have the reminder set for {abrv2FullDay[rDay]} at{" "}
              {defaultTime.toFormat("h:mm a ZZZZ")}.
            </Typography>
            <DeleteReminder />
          </>
        ) : (
          ""
        )}
        <SetRemindersForm day={rDay} defaultTime={defaultTime} />
      </Stack>
    </StyledPaperCard>
  );
}
