import React, { useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { apiSlice } from "../util/apiSlice";
import { firebaseApp } from "../util/constants";
import ConfirmSubmitButton from "./ConfirmSubmitButton";
import Loading from "./Loading";

export default function JoinTeamForm({ userData }) {
  const {
    data: teamNames,
    isSuccess,
    isError,
  } = apiSlice.useGetTeamNamesQuery();

  const [error, setError] = useState(null);
  const [teamName, setTeamName] = useState(null);

  const handleChange = (event, value, reason) => {
    setTeamName(value);
  };

  const onClick = (event) => {
    event.preventDefault();
    if (!teamName) {
      setError("Team Name cannot be empty!");
      return false;
    }
    return true;
  };

  if (isError) {
    return <>Sorry there was an unkown error. We will fix it shortly.</>;
  } else if (!isSuccess || !userData) {
    return <Loading />;
  } else {
    const options = userData.teamNames
      ? teamNames.filter((team) => !userData.teamNames.includes(team))
      : teamNames;
    return (
      <Stack spacing={2}>
        <Typography variant="h4">Join Existing Team</Typography>
        <Typography variant="h6">Select Team Name</Typography>
        <Autocomplete
          options={options}
          sx={{
            width: 209,
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder={"Select Team"} />
          )}
          value={teamName}
          onChange={handleChange}
          autoComplete
          disablePortal
        />
        <Typography>
          This is final. You won't be able to remove the team you are joining.
        </Typography>
        <ConfirmSubmitButton
          doNotResetIfGood
          data={{
            teamName: teamName,
            email: firebaseApp.auth().currentUser.email,
          }}
          postTo="/join-team"
          what="Team Selection"
          onClick={onClick}
          disabled={!!error}
          sx={{ width: "fit-content" }}
          mutationHook={
            apiSlice.useSetNewDataAndReloadUserDataIfSuccessMutation
          }
        />
      </Stack>
    );
  }
}
