import React from "react";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { abrv2FullDay } from "../util/constants";

export default function WeekdayPicker(props) {
  return (
    <Select {...props}>
      {Object.keys(abrv2FullDay).map((abrv) => (
        <MenuItem value={abrv} key={abrv}>
          {abrv2FullDay[abrv]}
        </MenuItem>
      ))}
    </Select>
  );
}
