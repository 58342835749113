import React from "react";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import Stack from "@mui/material/Stack";

function Change({ change }) {
  let ChangeIcon = HorizontalRuleIcon;
  let color = "inherit";

  if (change < 0) {
    ChangeIcon = ArrowDownwardIcon;
    color = "res.loss";
  } else if (change > 0) {
    ChangeIcon = ArrowUpwardIcon;
    color = "res.win";
  }

  return (
    <Stack
      display="inline-flex"
      direction="row"
      alignItems="baseline"
      color={color}
      wrap="nowrap"
    >
      <ChangeIcon
        sx={{
          fontSize: "inherit",
          alignSelf: "center",
        }}
      />
      {Math.abs(change)}
    </Stack>
  );
}

export default function Rank({ standing, lastStanding }) {
  if (!standing) {
    return <></>;
  }

  const suffixes = {
    1: "st",
    2: "nd",
    3: "rd",
  };

  return (
    <>
      {`${standing}${suffixes[standing % 10] ?? "th"} `}
      {lastStanding ? (
        <>
          {"("}
          <Change change={lastStanding - standing || 0} />
          {")"}
        </>
      ) : (
        ""
      )}
    </>
  );
}
