import React from "react";

import { useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";

import square_white_logo from "../logos/square_white_logo.png";
import wide_white_logo from "../logos/wide_white_logo.png";
import { LinkBehavior } from "../util/constants";

export default function Title({ sx, userData, ...otherProps }) {
  const sxDefault = {
    display: "grid",
    columnGap: 1,
    justifyItems: "center",
    gridTemplateRows: "auto",
    gridTemplateAreas: `"topLeft    right"
                        "bottomLeft right"`,
  };

  const theme = useTheme();
  const isXSScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let logo;
  let width;
  if (isXSScreen) {
    logo = square_white_logo;
    width = 70;
  } else {
    logo = wide_white_logo;
    width = 200;
  }
  return (
    <Button
      sx={{
        ...sxDefault,
        ...sx,
      }}
      component={LinkBehavior}
      to={userData ? `/?team=${encodeURIComponent(userData.teamName)}` : "/"}
      color="inherit"
      {...otherProps}
    >
      <img src={logo} width={width} alt="JK Fball Pool Logo" />
    </Button>
  );
}
