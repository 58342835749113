import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { apiSlice } from "../util/apiSlice";
import { weekModes } from "../util/constants";
import StyledPaperCard from "./StyledPaperCard";
import TeamsStatsTable from "./TeamsStatsTable";

export default function Stats() {
  const weekResponse = apiSlice.useGetWeekQuery();
  const week = weekResponse.data;

  const teamsHistoryResponse = apiSlice.useGetTeamsHistoryQuery();
  const teamsHistory = teamsHistoryResponse.data;
  return (
    <Stack spacing={3} padding={3}>
      <StyledPaperCard>
        <Typography variant="h4">
          NFL Teams Stats (
          {!week || week.N < 0
            ? "Final"
            : week.mode !== weekModes.PICK
              ? `${week.name} - In progress`
              : `${week.lastWeekName}${week.N === 1 ? "" : " - Completed"}`}
          )
        </Typography>
      </StyledPaperCard>
      {teamsHistory && week ? (
        <TeamsStatsTable teamsHistory={teamsHistory} week={week} />
      ) : (
        ""
      )}
    </Stack>
  );
}
