import React, { useState } from "react";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";

import { apiSlice } from "../util/apiSlice";
import ConfirmSubmitButton from "./ConfirmSubmitButton";
import StyledPaperCard from "./StyledPaperCard";

export default function AllowNewUsersForm({ userData }) {
  const defaultState = userData.pendingUsers.reduce((initialState, uid) => {
    return {
      ...initialState,
      [uid]: { canJoinTeam: false },
    };
  }, {});

  const [formState, setFormState] = useState(defaultState);

  const handleChange = (event) => {
    const newState = { ...formState };
    newState[event.target.name].canJoinTeam = event.target.checked;
    setFormState(newState);
  };

  return (
    <StyledPaperCard>
      <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
        <Typography variant="h6">
          Select users that you want to allow access to control your team:
        </Typography>
        <FormControl sx={{ ml: 3 }} component="fieldset" variant="standard">
          <FormGroup>
            {userData.pendingUsers.map((uid) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState[uid].canJoinTeam}
                      onChange={handleChange}
                      name={uid}
                      key={uid}
                    />
                  }
                  label={userData[uid]}
                  key={uid}
                />
              );
            })}
          </FormGroup>
        </FormControl>
        <ConfirmSubmitButton
          doNotResetIfGood
          data={formState}
          postTo="/confirm-join-team"
          what="New Partners"
          sx={{
            width: "fit-content",
            m: 0,
            ml: 2,
          }}
          mutationHook={apiSlice.useSetNewDataAndReloadUserDataMutation}
          disabled={userData.pendingUsers.length === 0}
        />
      </Box>
    </StyledPaperCard>
  );
}
