import React, { useEffect } from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { apiSlice } from "../util/apiSlice";
import Loading from "./Loading";

const WhatIf = React.forwardRef(function (
  { state, setState, ...otherProps },
  ref,
) {
  const {
    data: games,
    isSuccess,
    isLoading,
    isError,
  } = apiSlice.useGetGamesQuery();

  let gamesNotPlayed;
  let initialState = {};
  if (isSuccess) {
    gamesNotPlayed = games.filter((game) => !game.awayRes);
    initialState = gamesNotPlayed.reduce((initialState, game) => {
      return {
        ...initialState,
        [game.awayTeam]: "-",
        [game.homeTeam]: "-",
      };
    }, {});
  }

  const initialStateString = JSON.stringify(initialState);
  useEffect(() => {
    setState(JSON.parse(initialStateString));
  }, [initialStateString, setState]);

  if (isLoading) {
    return <Loading />;
  } else if (isError) {
    return <>Sorry there has been an error, try again later.</>;
  }
  const handleSelect = (event, team1, team2) => {
    setState({
      ...state,
      [team1]: event.target.value,
      [team2]:
        event.target.value === "-" || event.target.value === "T"
          ? event.target.value
          : event.target.value === "W"
            ? "L"
            : "W",
    });
  };

  const handleReset = () => {
    setState(
      gamesNotPlayed.reduce((obj, game) => {
        return {
          ...obj,
          [game.awayTeam]: "-",
          [game.homeTeam]: "-",
        };
      }, {}),
    );
  };
  const handleFavorites = () => {
    setState(
      gamesNotPlayed.reduce((obj, game) => {
        return {
          ...obj,
          [game.awayTeam]: game.homeOdds > 0 ? "W" : "L",
          [game.homeTeam]: game.homeOdds < 0 ? "W" : "L",
        };
      }, {}),
    );
  };
  return (
    <Stack spacing={3} ref={ref} {...otherProps}>
      <Stack direction="row" sx={{ gap: 2, flexWrap: "wrap" }}>
        <Button variant="outlined" onClick={handleFavorites}>
          Favorites Win
        </Button>
        <Button variant="outlined" onClick={handleReset}>
          Reset
        </Button>
      </Stack>
      <Grid container sx={{ gap: 1 }}>
        {Object.keys(state).length > 0
          ? gamesNotPlayed.map((game) => {
              return (
                <Grid
                  item
                  key={`${game.awayTeam}@${game.homeTeam}`}
                  xs={12}
                  sm={4}
                  md={4}
                  lg={2}
                  xl={1}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    width="100%"
                  >
                    <Select
                      value={state[game.awayTeam]}
                      onChange={(event) =>
                        handleSelect(event, game.awayTeam, game.homeTeam)
                      }
                    >
                      <MenuItem value="-">{"\u2014"}</MenuItem>
                      <MenuItem value="W">W</MenuItem>
                      <MenuItem value="T">T</MenuItem>
                      <MenuItem value="L">L</MenuItem>
                    </Select>
                    <Typography width="fit-content">
                      {game.awayTeam} @ {game.homeTeam}
                    </Typography>
                  </Stack>
                </Grid>
              );
            })
          : ""}
      </Grid>
    </Stack>
  );
});

export default WhatIf;
