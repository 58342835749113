import React, { useMemo } from "react";

import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

import { isAdmin } from "../util/constants";

export default function RosterTable({ roster, byUser }) {
  const columns = useMemo(() => {
    return getColumns(byUser);
  }, [byUser]);

  const data = useMemo(() => {
    return formatRosterForTable(roster, byUser);
  }, [roster, byUser]);

  const table = useMaterialReactTable({
    columns,
    data,
    enablePagination: false,
    enableGrouping: false,
    enableDensityToggle: false,
    initialState: {
      density: "compact",
      isLoading: true,
    },
    state: {
      isLoading: !roster,
      sorting: [{ id: columns[0].accessorKey, desc: false }],
    },
    mrtTheme: () => ({
      baseBackgroundColor: "#FFFFFF",
    }),
    muiTablePaperProps: {
      sx: {
        borderRadius: "20px",
        boxShadow: 0,
      },
    },
    muiTableHeadCellProps: () => {
      return {
        sx: {
          borderLeft: "0.05rem solid #EDEEF0",
          borderRight: "0.05rem solid #EDEEF0",
          pl: 2,
        },
      };
    },
    muiTableBodyCellProps: () => {
      return {
        sx: {
          borderLeft: "0.05rem solid #EDEEF0",
          borderRight: "0.05rem solid #EDEEF0",
          whiteSpace: "nowrap",
          pl: 2.5,
        },
      };
    },
  });

  return <MaterialReactTable table={table} />;
}

function getColumns(byUser) {
  let columns;
  if (byUser) {
    columns = [
      {
        header: "User",
        accessorKey: "displayName",
      },
    ];
    if (isAdmin()) {
      columns.push({
        header: "Email",
        accessorKey: "email",
        enableSorting: false,
      });
    }
    columns.push({
      header: "Teams",
      accessorKey: "teamNames",
      enableSorting: false,
    });
  } else {
    columns = [
      {
        header: "Team",
        accessorKey: "teamName",
      },
      {
        header: "Users",
        accessorKey: "displayNames",
        enableSorting: false,
      },
    ];
    if (isAdmin()) {
      columns.push({
        header: "Emails",
        accessorKey: "emails",
        enableSorting: false,
      });
    }
  }
  return columns;
}

function formatRosterForTable(roster, byUser) {
  if (!roster) {
    return [];
  }

  if (byUser) {
    return Object.keys(roster.byUser).map((uid) => {
      return {
        displayName: roster.uidToInfo[uid].displayName || "Name not available",
        email: roster.uidToInfo[uid].email,
        teamNames: roster.byUser[uid].join(", "),
      };
    });
  } else {
    return Object.keys(roster.byTeam).map((teamName) => {
      const displayNamesArr = roster.byTeam[teamName]
        .map((uid) => roster.uidToInfo[uid].displayName)
        .filter((n) => n);
      let displayNames = displayNamesArr.join(", ");
      const diff = roster.byTeam[teamName].length - displayNamesArr.length;
      if (displayNamesArr.length === 0) {
        displayNames = `${diff} user${diff > 1 ? "s" : ""}`;
      } else if (diff > 0) {
        displayNames += ` and ${diff} more user${diff > 1 ? "s" : ""}`;
      }
      return {
        teamName,
        displayNames,
        emails: roster.byTeam[teamName]
          .map((uid) => roster.uidToInfo[uid].email)
          .join(", "),
      };
    });
  }
}
