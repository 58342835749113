import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { apiSlice } from "../util/apiSlice";
import { getThursdayTeams, weekModes, weeks } from "../util/constants";
import { B } from "./TextStyles";

function getBubbleStyle(weekName, i, userData, mode, isThurPick) {
  const colors = {
    default: "inherit",
    L: "res.loss",
    T: "res.loss",
    W: "res.win",
  };
  if (userData) {
    if (i < userData.prevRes.length) {
      return {
        color: colors[userData.prevRes[i]],
        text: userData.prevPicks[i],
        textColor: "white",
        border: 0,
        component: "div",
      };
    } else if (i === userData.prevRes.length && userData.currentRes) {
      return {
        color: colors[userData.currentRes],
        text: userData.currentPick,
        textColor: "white",
        border: 0,
        component: "div",
      };
    } else if (
      i === userData.prevRes.length &&
      (mode === weekModes.LOCK_ALL ||
        (mode === weekModes.LOCK_THUR && isThurPick))
    ) {
      return {
        color: colors.default,
        text: userData.currentPick,
        textColor: colors.default,
        border: 2,
        component: "a",
      };
    }
  }
  return {
    color: colors.default,
    text: weekName,
    textColor: colors.default,
    border: 2,
    component: "a",
  };
}

export default function ResultsHistory({ userData, week, sx }) {
  const { data: teamsStats, isSuccess: isTSSucces } =
    apiSlice.useGetTeamsStatsQuery();
  const isThurPick =
    isTSSucces &&
    userData &&
    getThursdayTeams(teamsStats).includes(userData.currentPick);
  const mode = week && week.mode;

  const defaultSx = {
    display: "flex",
    gap: 2,
    justifyContent: "center",
    flexWrap: "wrap",
  };
  return (
    <Box sx={{ ...defaultSx, ...sx }}>
      {weeks.map((weekName, i) => {
        const { color, text, textColor, border, component } = getBubbleStyle(
          weekName,
          i,
          userData,
          mode,
          isThurPick,
        );
        return (
          <Box
            backgroundColor={color}
            key={weekName}
            href={`https://www.espn.com/nfl/schedule/_/week/${
              i === 21 ? 5 : (i % 18) + 1
            }/year/2024/seasontype/${i < 18 ? 2 : 3}`}
            component={component}
            sx={{
              border,
              borderRadius: 100,
              width: 40,
              height: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: textColor,
              textDecoration: "none",
            }}
          >
            <Typography variant="div">
              <B>{text}</B>
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}
