import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import TeamNameSelect from "./TeamNameSelect";

export default function UpdateCurrentTeam({ userData }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={3}
      sx={{ flexWrap: "wrap" }}
    >
      <Typography variant="h6">Current Team:</Typography>
      <TeamNameSelect userData={userData} variant="h6" setDefault={true} />
    </Stack>
  );
}
