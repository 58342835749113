import React from "react";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Link as RouterLink } from "react-router-dom";

import { apiSlice } from "../util/apiSlice";
import { getDefaultPicks } from "../util/constants";
import AddTeamForms from "./AddTeamForms";
import DashboardTitleCard from "./DashboardTitleCard";
import LastSeasonCard from "./LastSeasonCard";
import LastWeekCard from "./LastWeekCard";
import Payment from "./Payment";
import StyledPaperCard from "./StyledPaperCard";
import TeamNameSelect from "./TeamNameSelect";
import { A } from "./TextStyles";
import ThisWeekCard from "./ThisWeekCard";

function StackWrapper(props) {
  return <Stack spacing={2} padding={3} {...props} />;
}

function NewUser({ userData }) {
  return (
    <StackWrapper>
      <AddTeamForms userData={userData} spacing={3} padding={3} />
    </StackWrapper>
  );
}

function AddTeamInstructions({ userData }) {
  return (
    <StyledPaperCard sx={{ pt: 2, pb: 2 }}>
      <Typography variant="h6" m={0}>
        To Create or Join another team head over to the{" "}
        <RouterLink
          to={
            userData
              ? `/settings?team=${encodeURIComponent(userData.teamName)}`
              : "/settings"
          }
        >
          settings tab
        </RouterLink>
        .
      </Typography>
    </StyledPaperCard>
  );
}

function NoAccess({ userData }) {
  return (
    <StackWrapper>
      <AddTeamInstructions userData={userData} />
      <StyledPaperCard>
        <Typography variant="h6" m={3}>
          You don't have access to <A>{userData.teamName}</A>. Please select a
          different team: <TeamNameSelect userData={userData} variant="h6" />
        </Typography>
      </StyledPaperCard>
    </StackWrapper>
  );
}

function JoiningTeam({ userData }) {
  return (
    <StackWrapper>
      <AddTeamInstructions userData={userData} />
      <StyledPaperCard>
        <Typography variant="h6">
          You asked to join <TeamNameSelect userData={userData} variant="h6" />.
          Please wait for one of the members of the team to confirm your
          membership.
        </Typography>
      </StyledPaperCard>
    </StackWrapper>
  );
}

function NewTeam({ userData }) {
  return (
    <StackWrapper>
      <AddTeamInstructions userData={userData} />
      <Payment userData={userData} />
    </StackWrapper>
  );
}

export default function Dashboard({
  userData, // Garanteed not null because of Home
}) {
  const weekResponse = apiSlice.useGetWeekQuery();
  const week = weekResponse.data;

  const teamsStatsResponse = apiSlice.useGetTeamsStatsQuery();
  const teamsStats = teamsStatsResponse.data;

  if (userData.isNewUser) {
    return <NewUser userData={userData} />;
  }
  if (userData.noAccess) {
    return <NoAccess userData={userData} />;
  }
  if (userData.isJoiningTeam) {
    return <JoiningTeam userData={userData} />;
  }
  if (userData.isNewTeam) {
    return <NewTeam userData={userData} />;
  }

  const defaultPicks = getDefaultPicks(teamsStats, week, userData);
  return (
    <Grid container spacing={2} padding={3}>
      <Grid item xs={12} md={12} sx={{ display: "flex" }}>
        <DashboardTitleCard userData={userData} week={week} />
      </Grid>
      {week && week.N > 0 ? (
        <Grid item xs={12} sm={12} md={8}>
          <ThisWeekCard
            week={week}
            userData={userData}
            defaultPicks={defaultPicks}
            teamsStats={teamsStats}
          />
        </Grid>
      ) : (
        ""
      )}
      {week && week.N !== 1 ? (
        <Grid item xs={12} sm={12} md={4}>
          <LastWeekCard week={week} userData={userData} />
        </Grid>
      ) : (
        ""
      )}
      {week && week.N === 1 && userData.lastSeasonStanding ? (
        <Grid item xs={12} sm={12} md={4}>
          <LastSeasonCard lastSeasonStanding={userData.lastSeasonStanding} />
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
}
