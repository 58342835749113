import React from "react";

import Stack from "@mui/material/Stack";

import { apiSlice } from "../util/apiSlice";
import { weekModes } from "../util/constants";
import CreateTeamForm from "./CreateTeamForm";
import JoinTeamForm from "./JoinTeamForm";
import StyledPaperCard from "./StyledPaperCard";

export default function AddTeamForms({ userData, spacing, padding }) {
  const { data: week, isSuccess } = apiSlice.useGetWeekQuery();
  const maxLength = 30;
  return (
    <StyledPaperCard>
      <Stack spacing={spacing} padding={padding}>
        {isSuccess && week.N === 1 && week.mode !== weekModes.LOCK_ALL ? (
          <CreateTeamForm maxLength={maxLength} />
        ) : (
          ""
        )}
        <JoinTeamForm userData={userData} />
      </Stack>
    </StyledPaperCard>
  );
}
