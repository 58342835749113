import React from "react";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { nWeeks, teamNames } from "../util/constants";
import Rank from "./Rank";
import StyledPaperCard from "./StyledPaperCard";
import { B } from "./TextStyles";

export default function LastWeekCard({ userData, week }) {
  if (!week) {
    return <></>;
  }
  const lastRes = userData.prevRes[userData.prevRes.length - 1];
  // const pts = lastRes === "W" ? (userData.lost ? 2 : 3 ) : 0;
  return (
    <StyledPaperCard>
      <Stack sx={{ gap: 2 }}>
        <Typography variant="h5">LAST WEEK</Typography>
        <Divider sx={{ marginBottom: 1 }} />
        <Typography component="div" gutterBottom>
          {!week.lastWeekName.includes("Week") ? (
            <>
              <B>{week.lastWeekName}</B>
              <br />
            </>
          ) : (
            ""
          )}
          <B>Week {week ? (week.N > 0 ? week.N - 1 : nWeeks) : "#"}</B> of{" "}
          {nWeeks}
          <br />
          You picked:{" "}
          <B>
            The {teamNames[userData.prevPicks[userData.prevPicks.length - 1]]}
          </B>
          <br />
          {/* Game score: <B>NYJ 17-14 CIN</B>
          <br /> */}
          You <B>{lastRes === "W" ? "Won." : "Lost."}</B>
          {/* <br />
          Pool result: <B>+{pts} pts</B> */}
          <br />
          Your <B>Standing</B>:{" "}
          <Rank
            standing={userData.prevStandings[userData.prevStandings.length - 1]}
            lastStanding={
              userData.prevStandings[userData.prevStandings.length - 2]
            }
          />
        </Typography>
      </Stack>
    </StyledPaperCard>
  );
}
