import React from "react";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import { teamNames, weekModes } from "../util/constants";

export default function SelectPick({
  userData,
  week,
  byeTeams,
  thurTeams,
  onChange,
  value,
}) {
  let teamOptions = Object.keys(teamNames).filter((team) => {
    return !(
      byeTeams.includes(team) ||
      userData.usedPicks.includes(team) ||
      (week &&
        week.mode === weekModes.LOCK_THUR &&
        thurTeams &&
        thurTeams.includes(team))
    );
  });

  if (teamOptions.length === 0) {
    return (
      <Typography gutterBottom>
        You have picked all the available teams. You cannot make a pick this
        week.
      </Typography>
    );
  }

  return (
    <Select
      sx={{
        backgroundColor: value ? `team.${value}.primary` : "white",
        color: value ? "white" : "black",
        fontWeight: 700,
        width: 280,
      }}
      className={value ? "makeIconWhite" : ""}
      value={value}
      onChange={onChange}
    >
      {teamOptions.map((team) => (
        <MenuItem value={team} key={team}>
          {teamNames[team]}
        </MenuItem>
      ))}
    </Select>
  );
}
