import React, { useState } from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Link from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { apiSlice } from "../util/apiSlice";
import { ADMIN_EMAIL, modalStyle } from "../util/constants";
import ConfirmSubmitButton from "./ConfirmSubmitButton";

export default function ReactivateTeamModal({ prevTeamNames }) {
  let initialState = {};
  prevTeamNames.forEach((teamName) => {
    initialState[teamName] = true;
  });

  const [formState, setFormState] = useState(initialState);

  const handleFormChange = (event) => {
    const newState = { ...formState };
    newState[event.target.name] = event.target.checked;
    setFormState(newState);
  };

  return (
    <Modal open={true}>
      <Stack
        sx={{
          ...modalStyle,
          display: "flex",
          gap: 2,
          width: { xs: "100%", md: "80%" },
          overflow: "scroll",
          maxHeight: "100%",
        }}
      >
        <Typography variant="h4" color="res.win">
          WELCOME BACK
        </Typography>
        <Typography variant="h6">
          Looks like you have {prevTeamNames.length === 1 ? "a team" : "teams"}{" "}
          from last season available to reactivate. Please select which of your
          teams to activate and which to remove from your account.
        </Typography>
        <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
          <FormLabel component="legend">
            <Typography variant="h6" color="res.win">
              CHECK BOX TO REACTIVATE TEAM
            </Typography>
            <Typography variant="body">*note this action is final*</Typography>
          </FormLabel>
          <FormGroup>
            {prevTeamNames.map((teamName) => {
              return (
                <Stack
                  direction="row"
                  display="inline-flex"
                  alignItems="baseline"
                  key={teamName}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState[teamName]}
                        onChange={handleFormChange}
                        name={teamName}
                      />
                    }
                    label={teamName}
                    sx={{ width: "fit-content" }}
                  />
                </Stack>
              );
            })}
          </FormGroup>
        </FormControl>
        <Typography variant="body">
          You can still create or join new teams, regardless of team
          reactivation, from the settings tab. Teams from last season with
          multiple owners will still be available for reactivation for all
          owners, even if they are removed from your account.
          <br />
          If you incorrectly reactivate or remove a team, contact the committee
          at{" "}
          <Link color="inherit" href={`mailto:${ADMIN_EMAIL}`}>
            {ADMIN_EMAIL}
          </Link>
        </Typography>
        <ConfirmSubmitButton
          data={formState}
          postTo="/reactivate-teams"
          mutationHook={
            apiSlice.useSetNewDataAndReloadUserDataIfSuccessMutation
          }
          what="Team Selection"
          variant="contained"
          doNotResetIfGood
          sx={{
            width: "fit-content",
            alignSelf: "center",
            ":hover": { backgroundColor: "res.win" },
          }}
        />
      </Stack>
    </Modal>
  );
}
