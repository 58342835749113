import React from "react";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function TimeCounter({ timeObj }) {
  if (!timeObj) {
    return <></>;
  }
  return Object.keys(timeObj).map((title) => {
    const content = timeObj[title];
    if (title === "milliseconds") {
      return <React.Fragment key="title"></React.Fragment>;
    }
    return (
      <Paper
        elevation={0}
        key={title}
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          borderRadius: "10px",
        }}
      >
        <Stack
          spacing={0}
          alignItems="center"
          sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}
        >
          <Typography variant="h4">{content}</Typography>
          <Typography variant="caption">{title.toUpperCase()}</Typography>
        </Stack>
      </Paper>
    );
  });
}
