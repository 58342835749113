import React from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";

export default function PictureCard({
  children,
  image,
  sx,
  textPadding,
  pictureOffest,
  ...otherProps
}) {
  const defaultSx = {
    width: "100%",
    height: "100%",
    borderRadius: 5,
    position: "relative",
  };
  return (
    <Card elevation={0} sx={{ ...defaultSx, ...sx }} {...otherProps}>
      <CardMedia
        component="img"
        height="100%"
        image={image}
        sx={{
          position: "absolute",
          zIndex: 2,
          overflow: "visible",
          marginTop: pictureOffest || 0,
        }}
      />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          bgcolor: "rgba(0, 0, 0, 0.54)",
          color: "white",
          padding: "10px",
          zIndex: 3,
          position: "relative",
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", padding: textPadding || "inherit" }}
        >
          {children}
        </Stack>
      </Box>
    </Card>
  );
}
