import React from "react";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import { teamNames } from "../util/constants";

export default function SelectPick({ availablePicks, onChange, pick }) {
  if (availablePicks.length === 0) {
    return (
      <Typography gutterBottom>
        You have picked all the available teams. You cannot make a pick this
        week.
      </Typography>
    );
  }

  return (
    <Select
      sx={{
        backgroundColor: pick ? `team.${pick}.primary` : "white",
        color: pick ? "white" : "black",
        fontWeight: 700,
        width: 280,
      }}
      className={pick ? "makeIconWhite" : ""}
      value={pick}
      onChange={onChange}
    >
      {availablePicks.map((pick) => (
        <MenuItem value={pick} key={pick}>
          {teamNames[pick]}
        </MenuItem>
      ))}
    </Select>
  );
}
