import React, { useEffect, useState } from "react";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";

import { useSearchParams } from "react-router-dom";

import { apiSlice } from "../util/apiSlice";
import { firebaseApp } from "../util/constants";

export default function ConfirmSubmitButton({
  data,
  postTo,
  what,
  onClick,
  disabled,
  sx,
  SuccessComp,
  successCompProps,
  doNotResetIfGood,
  mutationHook,
  variant,
  runAfterSuccess,
  severityOverride,
  hideConfirm,
}) {
  const hook = mutationHook ?? apiSlice.useSetNewDataMutation;
  const [setNewData, { isLoading }] = hook();

  const [severity, setSeverity] = useState("");
  const [alertText, setAlertText] = useState("");
  const [disabledButton, setDisabled] = useState(false);

  const alertTexts = {
    unkError: `Sorry, there was an error when submitting your ${what}. Try again later`,
    success: `Confirmed ${what}!`,
  };

  let [searchParams] = useSearchParams();
  let teamName = searchParams.get("team");
  const uid = firebaseApp.auth().currentUser.uid;

  useEffect(() => {
    setSeverity(severityOverride);
    setAlertText(severityOverride === "success" ? alertTexts.success : "");
  }, [severityOverride, alertTexts.success]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (onClick && !onClick(event)) {
      return;
    }
    setDisabled(true);
    try {
      await setNewData({
        body: {
          data,
          uid,
          teamName,
        },
        url: postTo,
      }).unwrap();
      setSeverity("success");
      setAlertText(alertTexts.success);
      if (!doNotResetIfGood) {
        setDisabled(false);
      }
      if (runAfterSuccess) {
        runAfterSuccess();
      }
    } catch (err) {
      setDisabled(false);
      setSeverity("error");
      setAlertText(
        err && err.data && err.data.error
          ? err.data.error
          : alertTexts.unkError,
      );
      if (err.response) {
        console.log(err.response);
        console.log("server responded");
      } else if (err.request) {
        console.log(err.request);
        console.log("network error");
      } else {
        console.log(err);
        console.log("error");
      }
    }
  };

  return (
    <>
      <Button
        variant={variant || "outlined"}
        sx={{ m: 2, ...sx }}
        disabled={!data || disabled || disabledButton || isLoading}
        color={severity || "primary"}
        onClick={handleSubmit}
      >
        {hideConfirm ? "" : "Confirm "}
        {what}
      </Button>
      {severity ? (
        <Alert sx={sx} severity={severity}>
          {alertText}
        </Alert>
      ) : (
        ""
      )}
      {SuccessComp && severity === "success" ? (
        <SuccessComp {...successCompProps} />
      ) : (
        ""
      )}
    </>
  );
}
