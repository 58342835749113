import React from "react";

import { useOutletContext } from "react-router-dom";

import Dashboard from "./Dashboard";
import Welcome from "./Welcome";

export default function Home() {
  const { userData } = useOutletContext();

  if (userData) {
    return <Dashboard userData={userData} />;
  }

  return <Welcome />;
}
