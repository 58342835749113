import React from "react";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Rank from "./Rank";
import StyledPaperCard from "./StyledPaperCard";
import { B } from "./TextStyles";

export default function LastSeasonCard({ lastSeasonStanding }) {
  return (
    <StyledPaperCard>
      <Stack sx={{ gap: 2 }}>
        <Typography variant="h5">LAST SEASON</Typography>
        <Divider sx={{ marginBottom: 1 }} />
        <Typography component="div" gutterBottom>
          Your <B>Standing</B>: <Rank standing={lastSeasonStanding} /> of 152.
        </Typography>
      </Stack>
    </StyledPaperCard>
  );
}
