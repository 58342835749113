import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

function postDataQuery({ body, url }) {
  return {
    url,
    method: "POST",
    body,
  };
}

function postTestDataQuery({ body, url }) {
  console.log({
    url,
    method: "POST",
    body,
  });
  return {
    url,
    method: "POST",
    body,
  };
}

let letApiSlice;
if (window.location.host.slice(0, 9) !== "localhost") {
  letApiSlice = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({ baseUrl: "/api" }),
    tagTypes: ["userData", "admin"],
    endpoints: (builder) => ({
      getVersion: builder.query({
        query: () => "/version",
      }),
      getAdminData: builder.query({
        query: (uid) => `/admin?uid=${uid}`,
        providesTags: ["admin"],
      }),
      getUserData: builder.query({
        query: ({ uid, teamName }) =>
          `/user-data?uid=${uid}&teamName=${encodeURIComponent(teamName)}`,
        providesTags: ["userData"],
      }),
      getWeek: builder.query({
        query: () => "/week",
      }),
      getTeamNames: builder.query({
        query: () => `/team-names`,
      }),
      getTeamsStats: builder.query({
        query: () => "/nfl-teams-week",
      }),
      getTeamsHistory: builder.query({
        query: () => "/nfl-teams-stats",
      }),
      getStandings: builder.query({
        query: () => "/standings",
      }),
      getGames: builder.query({
        query: () => "/games",
      }),
      getRoster: builder.query({
        query: (uid) => `/roster?uid=${uid}`,
      }),
      setNewDataAndReloadUserData: builder.mutation({
        query: postDataQuery,
        invalidatesTags: ["userData"],
      }),
      setNewDataAndReloadUserDataIfSuccess: builder.mutation({
        query: postDataQuery,
        invalidatesTags: (result, error) => (error ? [] : ["userData"]),
      }),
      setNewUsers: builder.mutation({
        query: postDataQuery,
        invalidatesTags: ["admin"],
      }),
      setNewData: builder.mutation({
        query: postDataQuery,
      }),
    }),
  });
} else {
  letApiSlice = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({ baseUrl: "/TEST_JSON" }),
    tagTypes: ["userData", "admin"],
    endpoints: (builder) => ({
      getVersion: builder.query({
        query: () => "/version.json",
      }),
      getAdminData: builder.query({
        query: (uid) => {
          console.log(`/admin?uid=${encodeURIComponent(uid)}`);
          return `/admin.json`;
        },
        providesTags: ["admin"],
      }),
      getUserData: builder.query({
        query: ({ uid, teamName }) => {
          console.log(
            `/user-data?uid=${encodeURIComponent(
              uid,
            )}&teamName=${encodeURIComponent(teamName)}`,
          );
          return `/user-data-${teamName}.json`;
        },
        providesTags: ["userData"],
      }),
      getTeamNames: builder.query({
        query: () => {
          console.log(`/team-names`);
          return `/team-names.json`;
        },
      }),
      getWeek: builder.query({
        query: () => {
          console.log("/week");
          return "/week.json";
        },
      }),
      getTeamsStats: builder.query({
        query: () => {
          console.log("/nfl-teams-week");
          return "/nfl-teams-week.json";
        },
      }),
      getTeamsHistory: builder.query({
        query: () => {
          console.log("/nfl-teams-stats");
          return "/nfl-teams-stats.json";
        },
      }),
      getStandings: builder.query({
        query: () => {
          console.log("/standings");
          return "/standings.json";
        },
      }),
      getGames: builder.query({
        query: () => {
          console.log("/games");
          return "/games.json";
        },
      }),
      getRoster: builder.query({
        query: (uid) => {
          console.log(`/roster?uid=${uid}`);
          return "/roster.json";
        },
      }),
      setNewDataAndReloadUserData: builder.mutation({
        query: postTestDataQuery,
        invalidatesTags: ["userData"],
      }),
      setNewDataAndReloadUserDataIfSuccess: builder.mutation({
        query: postTestDataQuery,
        invalidatesTags: (result, error) => (error ? [] : ["userData"]),
      }),
      setNewAdminData: builder.mutation({
        query: postTestDataQuery,
        invalidatesTags: ["admin"],
      }),
      setNewData: builder.mutation({
        query: postTestDataQuery,
      }),
    }),
  });
}

export const apiSlice = letApiSlice;
