import React, { useMemo } from "react";

import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

import {
  styleCellResult,
  teamNames,
  weekModes,
  weeks,
} from "../util/constants";

export default function TeamsStatsTable({ teamsHistory, week }) {
  const columns = useMemo(() => {
    return getTableColumns(week);
  }, [week]);

  const data = useMemo(() => {
    return formatTeamsHistoryForTable(teamsHistory, week);
  }, [teamsHistory, week]);

  let sorting = [];
  if (columns.length > 1) {
    sorting = [{ id: columns[1].columns[0].accessorKey, desc: true }];
  }
  const table = useMaterialReactTable({
    columns,
    data,
    enablePagination: false,
    enableGrouping: false,
    groupedColumnMode: "remove",
    enableDensityToggle: false,
    initialState: {
      density: "compact",
      sorting,
      isLoading: true,
      columnPinning: { left: ["abrv"] },
    },
    layoutMode: "grid-no-grow",
    state: {
      isLoading: !(teamsHistory && week),
    },
    mrtTheme: () => ({
      baseBackgroundColor: "#FFFFFF",
    }),
    muiTablePaperProps: {
      sx: {
        borderRadius: "20px",
        boxShadow: 0,
      },
    },
    muiTableHeadCellProps: () => {
      return {
        sx: {
          borderLeft: "0.05rem solid #EDEEF0",
          borderRight: "0.05rem solid #EDEEF0",
          "& .Mui-TableHeadCell-Content": {
            justifyContent: "center",
          },
        },
      };
    },
    muiTableBodyCellProps: () => {
      return {
        sx: {
          borderLeft: "0.05rem solid #EDEEF0",
          borderRight: "0.05rem solid #EDEEF0",
          justifyContent: "center",
        },
      };
    },
  });

  return <MaterialReactTable table={table} />;
}

function formatTeamsHistoryForTable(teamsHistory, week) {
  if (!teamsHistory || !week) {
    return [];
  }

  const weekN = week.N < 0 ? weeks.length + 1 : week.N;

  return Object.keys(teamsHistory).map((team) => {
    const teamData = teamsHistory[team];
    let row = {
      abrv: team,
      fullName: teamNames[team],
      W: 0,
      L: 0,
      T: 0,
    };

    teamData.prevRes.forEach((res) => {
      if (res) {
        row[res] = ++row[res];
      }
    });

    for (let i = 1; i < weekN; i++) {
      row[`week${i}picks`] = teamData.prevPicks[i - 1] || 0;
      row[`week${i}res`] = teamData.prevRes[i - 1];
    }

    if (teamData.currentRes) {
      row[`week${weekN}res`] = teamData.currentRes || "";
      row[teamData.currentRes] = ++row[teamData.currentRes];
    }
    if (week.mode === weekModes.LOCK_THUR) {
      row[`week${weekN}picks`] = teamData.currentPicks;
    } else if (week.mode === weekModes.LOCK_ALL) {
      row[`week${weekN}picks`] = teamData.currentPicks || 0;
    }

    return row;
  });
}

function getTableColumns(week) {
  let columns = [
    {
      header: "Info",
      id: "info",
      columns: [
        {
          header: "Team",
          accessorKey: "abrv",
          size: 105,
        },
        {
          header: "Team Name",
          accessorKey: "fullName",
          muiTableBodyCellProps: () => {
            return {
              sx: { whiteSpace: "nowrap" },
            };
          },
        },
        { header: "W", accessorKey: "W", size: 85 },
        { header: "L", accessorKey: "L", size: 85 },
        { header: "T", accessorKey: "T", size: 85 },
      ],
    },
  ];

  if (!week) {
    return columns;
  }

  let weekColumns = [];
  const maxWeek =
    week.N < 0
      ? weeks.length
      : week.mode !== weekModes.PICK
        ? week.N
        : week.N - 1;
  for (let i = maxWeek; i > 0; i--) {
    weekColumns.push({
      header: `${weeks[i - 1]}`,
      accessorKey: `week${i}picks`,
      size: 90,
      muiTableBodyCellProps: ({ row }) => {
        return {
          sx: {
            ...styleCellResult(row.original, `week${i}res`),
            borderLeft: "0.05rem solid #EDEEF0",
            borderRight: "0.05rem solid #EDEEF0",
          },
        };
      },
    });
  }

  if (weekColumns.length > 0) {
    columns.push({
      header: "Week",
      id: "week",
      columns: weekColumns,
      muiTableHeadCellProps: () => {
        return {
          sx: {
            borderLeft: "0.05rem solid #EDEEF0",
            borderRight: "0.05rem solid #EDEEF0",
            "& .Mui-TableHeadCell-Content": {
              justifyContent: "left",
            },
          },
        };
      },
    });
  }

  return columns;
}
