import React, { useEffect, useState } from "react";

import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";

import { useOutletContext } from "react-router-dom";

import { apiSlice } from "../util/apiSlice";
import { isTeamConfirmed, weekModes } from "../util/constants";
import GridSubtitle from "./GridSubtitle";
import Loading from "./Loading";
import StandingsTable from "./StandingsTable";
import StyledPaperCard from "./StyledPaperCard";
import WhatIf from "./WhatIf";

export default function TheGrid() {
  const { userData } = useOutletContext();

  const [renderCount, setRenderCount] = useState(0);
  const maxRenders = 3;
  useEffect(() => {
    setRenderCount(Math.min(renderCount + 1, maxRenders));
  }, [renderCount, maxRenders]);

  let { data: week } = apiSlice.useGetWeekQuery();
  let { data: standings } = apiSlice.useGetStandingsQuery();

  if (renderCount < maxRenders) {
    standings = undefined;
    week = undefined;
  }

  const [showOnlyFavs, setShowOnlyFavs] = useState(false);
  const handleToggleFavs = (event, value) => {
    if (value !== null) {
      setShowOnlyFavs(value);
    }
  };

  const [whatIfState, setWhatIfState] = useState({});
  const [whatIf, setWhatIf] = useState(false);
  const handleToggleWhatIf = (event, value) => {
    if (value !== null) {
      setWhatIf(value);
    }
  };

  return (
    <Stack spacing={3} padding={3}>
      <StyledPaperCard>
        <Stack spacing={2}>
          <Typography variant="h4">
            The Grid (
            {!week || week.N < 0
              ? "Final Standings"
              : week.mode === weekModes.LOCK_ALL
                ? `${week.name} - In progress`
                : `${week.lastWeekName}${week.N === 1 ? "" : " - Completed"}`}
            )
          </Typography>
          {week && isTeamConfirmed(userData) ? (
            <GridSubtitle userData={userData} week={week} />
          ) : (
            ""
          )}
          {week && week.mode === weekModes.LOCK_ALL ? (
            <>
              <ToggleButtonGroup
                color="primary"
                value={whatIf}
                exclusive
                onChange={handleToggleWhatIf}
              >
                <ToggleButton value={false}>Current</ToggleButton>
                <ToggleButton value={true}>What If...</ToggleButton>
              </ToggleButtonGroup>
              <Collapse in={whatIf}>
                <WhatIf state={whatIfState} setState={setWhatIfState} />
              </Collapse>
            </>
          ) : (
            ""
          )}
          {isTeamConfirmed(userData) ? (
            <ToggleButtonGroup
              color="primary"
              value={showOnlyFavs}
              exclusive
              onChange={handleToggleFavs}
            >
              <ToggleButton value={false}>All</ToggleButton>
              <ToggleButton value={true}>Starred</ToggleButton>
            </ToggleButtonGroup>
          ) : (
            ""
          )}
        </Stack>
      </StyledPaperCard>
      {renderCount < maxRenders - 1 ? (
        <Paper elevation={0} sx={{ borderRadius: "20px", height: "200px" }}>
          <Loading />
        </Paper>
      ) : (
        <StandingsTable
          userData={userData}
          week={week}
          standings={standings}
          whatIfState={whatIfState}
          whatIf={whatIf}
          showOnlyFavs={showOnlyFavs}
        />
      )}
    </Stack>
  );
}
