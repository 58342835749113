import React, { useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { DateTime } from "luxon";

import { DateTimeNow, LinkBehavior } from "../util/constants";
import TimeCounter from "./TimeCounter";

function removeZeros(timeObj, units) {
  const timeObjCopy = { ...timeObj };
  for (let unit of units) {
    if (timeObjCopy[unit] === 0) {
      delete timeObjCopy[unit];
    } else {
      return timeObjCopy;
    }
  }
  return timeObjCopy;
}

function getTimeLeftObj(week) {
  if (week) {
    const lockTime = DateTime.fromISO(week.lockTime);
    const now = DateTimeNow();
    let thur;
    let other;

    const units = ["days", "hours", "minutes", "seconds", "milliseconds"];
    if (week.thurLockTime) {
      const thurLockTime = DateTime.fromISO(week.thurLockTime);
      if (thurLockTime > now) {
        thur = thurLockTime.diff(now, units).toObject();
        thur = removeZeros(thur, units);
      }
    }
    if (lockTime > now) {
      other = lockTime.diff(now, units).toObject();
      other = removeZeros(other, units);
    }
    return {
      thur,
      other,
    };
  } else {
    return {};
  }
}

export default function TimeLeft({ week, userData, isThurPick }) {
  const [timeLeft, setTimeLeft] = useState(getTimeLeftObj(week));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(getTimeLeftObj(week));
    }, 1000);

    return () => clearInterval(interval);
  }, [week]);

  return (
    <>
      {(!isThurPick && timeLeft.other) || (isThurPick && timeLeft.thur) ? (
        <Stack
          direction="row"
          flexWrap="wrap"
          sx={{ gap: 1, alignItems: "stretch" }}
        >
          <TimeCounter timeObj={isThurPick ? timeLeft.thur : timeLeft.other} />
          <Button
            sx={{
              padding: 0,
              maxWidth: "100%",
              "&:hover": {
                backgroundColor: "#DDDDDD",
              },
            }}
            component={LinkBehavior}
            to={
              userData
                ? `/pick?team=${encodeURIComponent(userData.teamName)}`
                : "/pick"
            }
          >
            <Paper
              elevation={0}
              variant="outlined"
              sx={{
                color: "primary.main",
                borderColor: "primary.main",
                height: "100%",
                backgroundColor: "transparent",
              }}
            >
              <Stack
                spacing={0}
                justifyContent="center"
                alignItems="center"
                sx={{ pt: 1, pb: 1, pl: 2, pr: 2, height: "100%" }}
              >
                <Typography variant="h5">
                  TO {userData.currentPick ? "EDIT YOUR" : "MAKE A"}{" "}
                  {isThurPick ? "THURSDAY" : "WEEKEND"} PICK
                </Typography>
              </Stack>
            </Paper>
          </Button>
        </Stack>
      ) : (
        ""
      )}
      {timeLeft.thur && !isThurPick ? (
        <Accordion elevation={0} sx={{ border: "none" }} disableGutters>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Show Thursday Countdown</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Stack
              direction="row"
              flexWrap="wrap"
              sx={{ gap: 1, alignItems: "stretch" }}
            >
              <TimeCounter timeObj={timeLeft.thur} />
              <Button
                sx={{
                  padding: 0,
                  maxWdith: "100%",
                  "&:hover": {
                    backgroundColor: "#DDDDDD",
                  },
                }}
                component={LinkBehavior}
                to={
                  userData
                    ? `/pick?team=${encodeURIComponent(userData.teamName)}`
                    : "/pick"
                }
              >
                <Paper
                  elevation={0}
                  variant="outlined"
                  sx={{
                    color: "primary.main",
                    borderColor: "primary.main",
                    height: "100%",
                    backgroundColor: "transparent",
                  }}
                >
                  <Stack
                    spacing={0}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ pt: 1, pb: 1, pl: 2, pr: 2, height: "100%" }}
                  >
                    <Typography variant="h5">
                      TO{" "}
                      {userData.currentPick && isThurPick
                        ? "EDIT YOUR"
                        : "MAKE A"}{" "}
                      THURSDAY PICK
                    </Typography>
                  </Stack>
                </Paper>
              </Button>
            </Stack>
          </AccordionDetails>
        </Accordion>
      ) : (
        ""
      )}
    </>
  );
}
