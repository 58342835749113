import React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loading(props) {
  const defaultSx = {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  };
  const { sx, ...otherProps } = props;
  return (
    <Box sx={{ ...defaultSx, ...sx }} {...otherProps}>
      <CircularProgress />
    </Box>
  );
}
