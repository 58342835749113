import { useMemo, useState } from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { apiSlice } from "../util/apiSlice";
import ConfirmSubmitButton from "./ConfirmSubmitButton";
import SelectPick from "./NewSelectPick";
import SelectTeam from "./SelectTeam";
import StyledPaperCard from "./StyledPaperCard";
import { B } from "./TextStyles";

export function AdminUpdateTeamPick({ teamsInfo }) {
  const teams = useMemo(() => {
    return Object.keys(teamsInfo).sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
  }, [teamsInfo]);
  const [team, setTeam] = useState(teams[0]);
  const [currentPick, setCurrentPick] = useState(teamsInfo[team].currentPick);
  const [picks, setPicks] = useState(teamsInfo[team].picks);
  const [pick, setPick] = useState(currentPick || picks[0]);

  const handleTeamChange = (event, newValue) => {
    setTeam(newValue);
    setCurrentPick(teamsInfo[newValue].currentPick);
    setPicks(teamsInfo[newValue].picks);
    setPick(teamsInfo[newValue].currentPick || teamsInfo[newValue].picks[0]);
  };

  const handlePickChange = (event) => {
    setPick(event.target.value);
  };

  return (
    <StyledPaperCard>
      <Stack spacing={2}>
        <Typography variant="h5" gutterBottom>
          Update team's pick
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          sx={{ alignItems: "center", flexWrap: "wrap" }}
        >
          <Typography variant="h6">Team:</Typography>
          <SelectTeam
            teams={teams}
            onChange={handleTeamChange}
            selectedTeam={team}
          />
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{ alignItems: "center", flexWrap: "wrap" }}
        >
          <Typography variant="h6">Pick:</Typography>
          <SelectPick
            availablePicks={picks}
            onChange={handlePickChange}
            pick={pick}
          />
        </Stack>
        {picks.length === 0 ? (
          ""
        ) : currentPick ? (
          currentPick === pick ? (
            <Typography component="div">
              Current pick is <B>{currentPick}</B>.
            </Typography>
          ) : (
            <Typography component="div">
              Upadting pick from <B>{currentPick}</B> to <B>{pick}</B>.
            </Typography>
          )
        ) : (
          <Typography component="div">
            No current pick. Setting new pick to be <B>{pick}</B>.
          </Typography>
        )}
        <ConfirmSubmitButton
          data={{
            team,
            pick,
          }}
          postTo="/admin-update-pick"
          what="Pick"
          mutationHook={apiSlice.useSetNewAdminDataMutation}
          disabled={(currentPick && currentPick === pick) || picks.length === 0}
          variant="contained"
          sx={{ width: 240, ml: 0 }}
        />
      </Stack>
    </StyledPaperCard>
  );
}
