import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import StyledPaperCard from "./StyledPaperCard";
import TeamBanner from "./TeamBanner";
import TeamNameSelect from "./TeamNameSelect";
import TeamOdds from "./TeamOdds";
import { A } from "./TextStyles";
import TimeLeft from "./TimeLeft";

export default function PickTitleCard({
  userData,
  week,
  teamsStats,
  isThurPick,
}) {
  return (
    <StyledPaperCard>
      <Stack spacing={2}>
        <Typography variant="h4" gutterBottom>
          <TeamNameSelect userData={userData} /> Pick (
          {week ? week.name : "week #"})
        </Typography>
        <TimeLeft week={week} userData={userData} isThurPick={isThurPick} />
        {userData.currentPick ? (
          <>
            <Typography variant="h5" pt={2}>
              Your Current Pick
            </Typography>
            <Stack sx={{ gap: 2, flexWrap: "wrap" }} direction="row">
              <TeamBanner team={userData.currentPick} />
              <TeamOdds team={userData.currentPick} teamsStats={teamsStats} />
            </Stack>
          </>
        ) : (
          ""
        )}
        {userData.sbScore ? (
          <>
            <Typography variant="h5" pt={2}>
              Your Current SB Score Prediction:{" "}
              <A lost={userData.lost}>{userData.sbScore} pts</A>
            </Typography>
          </>
        ) : (
          ""
        )}
      </Stack>
    </StyledPaperCard>
  );
}
