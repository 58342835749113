import React, { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { apiSlice } from "../util/apiSlice";
import { firebaseApp, modalStyle } from "../util/constants";

export default function UpdateEmailModal({ open, onClose }) {
  const [setNewData, { isLoading }] = apiSlice.useSetNewDataMutation();

  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [disableTF, setDisableTF] = useState(false);
  const [newEmail, setNewEmail] = useState("");

  const hanldeChange = (event) => {
    setNewEmail(event.target.value);
    setError(false);
    setHelperText("");
  };

  const errorMessages = {
    "auth/invalid-email": "Sorry this is not a valid email address.",
    "auth/email-already-in-use": "Sorry this email belongs ot another account.",
    "auth/requires-recent-login":
      "Sorry your session has expired. You need to login again.",
    other: "Sorry an unkown error has ocurred. Try again later.",
  };

  const oldEmail = firebaseApp.auth().currentUser.email;

  const handleError = (err) => {
    setDisableTF(false);
    setError(true);

    if (err.code in errorMessages) {
      setHelperText(errorMessages[err.code]);
      return;
    }

    setHelperText(errorMessages["other"]);
    if (err.response) {
      console.log(err.response);
      console.log("server responded");
    } else if (err.request) {
      console.log(err.request);
      console.log("network error");
    } else {
      console.log(err);
      console.log("error");
    }
  };

  const handleSubmit = async () => {
    setDisableTF(true);
    try {
      await firebaseApp.auth().currentUser.updateEmail(newEmail);
      try {
        await setNewData({
          body: {
            data: newEmail,
            uid: firebaseApp.auth().currentUser.uid,
          },
          url: "/update-email",
        }).unwrap();
        setHelperText(`Succesfully Updated email to: ${newEmail}`);
      } catch (err) {
        console.log(err);
        console.log("switching back to original email.");
        await firebaseApp.auth().currentUser.updateEmail(oldEmail);
        handleError(err);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleClose = () => {
    setHelperText("");
    setNewEmail("");
    setError(false);
    setDisableTF(false);
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Stack sx={{ gap: 2 }}>
          <Typography variant="h5" gutterBottom>
            Update Login Email
          </Typography>
          <Typography gutterBottom>
            You will have to use this email to login. This change is permanent.
          </Typography>
          <TextField
            label="New Email"
            onChange={hanldeChange}
            error={error}
            helperText={helperText}
            disabled={disableTF}
          />
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!newEmail || error || disableTF || isLoading}
              onClick={handleSubmit}
            >
              Confirm
            </Button>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
}
