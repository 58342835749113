import React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Provider } from "react-redux";
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";

import store from "../util/store";
import theme from "../util/theme";
import Admin from "./Admin";
import Home from "./Home";
import Layout from "./Layout";
import OldUserRoute from "./OldUserRoute";
import Pick from "./Pick";
import Roster from "./Roster";
import Rules from "./Rules";
import Settings from "./Settings";
import SignedInRoute from "./SignedInRoute";
import Stats from "./Stats";
import TheGrid from "./TheGrid";

const router = createBrowserRouter([
  {
    id: "root",
    path: "",
    Component: Layout,
    children: [
      {
        index: true,
        Component: Home,
      },
      {
        path: "pick",
        Component: OldUserRoute,
        children: [{ index: true, Component: Pick }],
      },
      {
        path: "grid",
        Component: TheGrid,
      },
      {
        path: "stats",
        Component: SignedInRoute,
        children: [{ index: true, Component: Stats }],
      },
      {
        path: "roster",
        Component: SignedInRoute,
        children: [{ index: true, Component: Roster }],
      },
      {
        path: "rules",
        Component: Rules,
      },
      {
        path: "settings",
        Component: SignedInRoute,
        children: [{ index: true, Component: Settings }],
      },
      {
        path: "admin",
        Component: SignedInRoute,
        children: [{ index: true, Component: Admin }],
      },
    ],
  },
  {
    id: "noMatch",
    path: "*",
    loader: NoMatchLoader,
  },
]);

function NoMatchLoader({ request }) {
  const url = new URL(request.url);
  const team = url.searchParams.get("team");
  let redirectUrl = "/";
  if (team) {
    redirectUrl += "?team=" + team;
  }
  return redirect(redirectUrl);
}

export default function App() {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RouterProvider
            router={router}
            fallbackElement={<p>Initial Load...</p>}
          />
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  );
}
