import React from "react";

import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { apiSlice } from "../util/apiSlice";
import { modalStyle } from "../util/constants";
import ConfirmSubmitButton from "./ConfirmSubmitButton";
import { B } from "./TextStyles";

export default function ChatLaunchModal() {
  return (
    <Modal open={true}>
      <Stack
        sx={{
          ...modalStyle,
          display: "flex",
          gap: 2,
          width: { xs: "100%", md: "80%" },
          overflow: "scroll",
          maxHeight: "100%",
        }}
      >
        <Typography variant="h4" color="res.win">
          NEW FEATURE!!
        </Typography>
        <Typography variant="body">
          You asked, we listened. <B>The pool now has an in-website chat.</B> To
          enable the chat feature for your account, click below. You also have
          the option of disabling this feature, which will hide the chat button
          on your screen. You can always change this in your settings tab.
        </Typography>
        <Stack direction="row">
          <ConfirmSubmitButton
            data={{}}
            postTo={"/enable-chat"}
            what={"Enable"}
            variant="contained"
            mutationHook={
              apiSlice.useSetNewDataAndReloadUserDataIfSuccessMutation
            }
            hideConfirm
            sx={{ backgroundColor: "res.win" }}
          />
          <ConfirmSubmitButton
            data={{}}
            postTo={"/disable-chat"}
            what={"Disable"}
            mutationHook={
              apiSlice.useSetNewDataAndReloadUserDataIfSuccessMutation
            }
            hideConfirm
          />
        </Stack>
        <Typography variant="body">
          Once enabled, the chat is a great way to communicate with other
          members of the football pool. Share exciting plays on Sundays, talk
          moments and games that led to big pool shake-ups, or discuss anything
          else on your mind. Find the chat button in the bottom right corner of
          your screen.
        </Typography>
        <Typography variant="body">
          Our chat feature is run through <B>Discord</B>, a popular messaging
          platform. Before you can send messages, you’ll have to create or
          log-in to a personal Discord account.
        </Typography>
      </Stack>
    </Modal>
  );
}
