import React from "react";

import Paper from "@mui/material/Paper";

export default function StyledPaperCard({ children, sx, ...otherProps }) {
  const defaultSx = {
    pt: 3,
    pl: 4,
    pb: 3,
    pr: 4,
    width: "100%",
    height: "100%",
    borderRadius: 5,
  };
  return (
    <Paper elevation={0} sx={{ ...defaultSx, ...sx }} {...otherProps}>
      {children}
    </Paper>
  );
}
