import React from "react";

import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";

import Loading from "./Loading";

export default function TeamOdds({ team, teamsStats }) {
  if (!teamsStats) {
    return <Loading />;
  } else if (teamsStats === "error") {
    return <Alert severity="error" sx={{ width: "fit-content" }} />;
  } else if (!teamsStats[team]) {
    return <></>;
  }

  return (
    <Typography variant="h5" align="center" pl={1} alignSelf="center">
      {`${teamsStats[team].odds} pts ${teamsStats[team].loc === "H" ? "vs" : "@"} ${teamsStats[team].opp}`}
    </Typography>
  );
}
