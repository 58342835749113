import React, { useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Toolbar from "@mui/material/Toolbar";

import NavTabs from "./NavTabs";
import SignInButton from "./SignInButton";
import Title from "./Title";

const iOS =
  typeof navigator !== "undefined" &&
  /iPad|iPhone|iPod/.test(navigator.userAgent);

export default function Header({ userData }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleOpenDrawer = () => setDrawerOpen(true);
  const handleCloseDrawer = () => setDrawerOpen(false);

  const isWinnerColor = !userData?.lost;

  document.querySelector("meta[name='theme-color']").content = isWinnerColor
    ? "#00A36C"
    : "#C41E3A";

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: `res.${isWinnerColor ? "win" : "loss"}`,
        color: "white",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: { xs: "wrap", sm: "nowrap", md: "nowrap" },
          }}
        >
          <SignInButton userData={userData} />

          <Title
            sx={{
              display: { xs: "grid", sm: "grid", md: "grid" },
              padding: { xs: 2, sm: 0, md: 0 },
            }}
            userData={userData}
          />

          <Box sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenDrawer}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            <SwipeableDrawer
              disableBackdropTransition={!iOS}
              disableDiscovery={iOS}
              anchor="top"
              open={drawerOpen}
              onOpen={handleOpenDrawer}
              onClose={handleCloseDrawer}
            >
              <NavTabs userData={userData} onChange={handleCloseDrawer} />
            </SwipeableDrawer>
          </Box>

          <Box sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
            <NavTabs userData={userData} />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
