import { createTheme } from "@mui/material/styles";

import { teamColors } from "./teamColors";

const theme = createTheme({
  typography: {
    fontFamily: ['"Cooper Hewitt"', "Roboto", "Arial"].join(","),
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    body1: {
      fontWeight: 500,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      smd: 900,
      md: 1050,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    background: {
      default: "#EDEEF0",
    },
    primary: {
      main: "#696969",
    },
    secondary: {
      main: "#FFFFFF",
    },
    res: {
      win: "#00A36C",
      loss: "#C41E3A",
    },
    error: {
      main: "#ff1744",
    },
    text: {
      accents: "#696969",
    },
    team: teamColors,
  },
});

export default theme;
