import React, { useState } from "react";

import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";

import { apiSlice } from "../util/apiSlice";
import { modalStyle } from "../util/constants";
import ConfirmSubmitButton from "./ConfirmSubmitButton";

export default function DeleteTeamModal({ open, onClose, teamData }) {
  const [disabled, setDisabled] = useState(false);

  const handleClose = () => {
    setDisabled(false);
    onClose();
  };

  const handleSuccess = () => {
    handleClose();
  };

  const onClick = () => {
    setDisabled(true);
    return true;
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Stack sx={{ ...modalStyle, display: "flex", gap: 2 }}>
        <Button variant="outlined" onClick={handleClose} sx={{ m: 2 }}>
          Cancel
        </Button>
        <ConfirmSubmitButton
          data={teamData}
          postTo="/delete-team"
          what={`Delete ${teamData.teamName}`}
          mutationHook={apiSlice.useSetNewUsersMutation}
          disabled={disabled}
          onClick={onClick}
          variant="contained"
          runAfterSuccess={handleSuccess}
          doNotResetIfGood
          sx={{ ":hover": { backgroundColor: "red" } }}
        />
      </Stack>
    </Modal>
  );
}
