import React, { useState } from "react";

import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";

import { apiSlice } from "../util/apiSlice";
import { modalStyle } from "../util/constants";
import ConfirmSubmitButton from "./ConfirmSubmitButton";

export default function DeleteReminderModal({ open, onClose }) {
  const [disabled, setDisabled] = useState(false);

  const handleClose = () => {
    setDisabled(false);
    onClose();
  };

  const handleSuccess = () => {
    handleClose();
  };

  const onClick = () => {
    setDisabled(true);
    return true;
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Stack sx={{ ...modalStyle }}>
        <ConfirmSubmitButton
          data={{}}
          postTo="/delete-reminder"
          what={"Delete Reminder"}
          mutationHook={
            apiSlice.useSetNewDataAndReloadUserDataIfSuccessMutation
          }
          disabled={disabled}
          onClick={onClick}
          variant="contained"
          runAfterSuccess={handleSuccess}
          doNotResetIfGood
          sx={{ ":hover": { backgroundColor: "red" } }}
        />
        <Button variant="outlined" onClick={handleClose} sx={{ m: 2 }}>
          Cancel
        </Button>
      </Stack>
    </Modal>
  );
}
