import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { apiSlice } from "../util/apiSlice";
import ConfirmSubmitButton from "./ConfirmSubmitButton";

export default function UpdateChatPreference({ userData }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={3}
      sx={{ flexWrap: "wrap" }}
    >
      <Typography variant="h6">
        Chat currently {userData.enableChat ? "enabled" : "disabled"}{" "}
      </Typography>
      <ConfirmSubmitButton
        data={{}}
        postTo={userData.enableChat ? "/disable-chat" : "/enable-chat"}
        what={userData.enableChat ? "Disable" : "Enable"}
        mutationHook={apiSlice.useSetNewDataAndReloadUserDataIfSuccessMutation}
        variant="contained"
        hideConfirm
      />
    </Stack>
  );
}
