import React, { useState } from "react";

import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import { useSearchParams } from "react-router-dom";

import { apiSlice } from "../util/apiSlice";
import { firebaseApp } from "../util/constants";
import { A } from "./TextStyles";

export default function TeamNameSelect({ userData, variant, setDefault }) {
  const [teamName, setTeamName] = useState(userData.teamName);
  let setSearchParams = useSearchParams()[1];
  const [setNewData, { isLoading }] =
    apiSlice.useSetNewDataAndReloadUserDataMutation();

  const handleChange = (event) => {
    setSearchParams({ team: event.target.value });
    setTeamName(event.target.value);
  };

  const handleChangeDefaultTeam = () => {
    setNewData({
      body: {
        data: teamName,
        uid: firebaseApp.auth().currentUser.uid,
      },
      url: "/update-default-team",
    });
  };

  return (
    <>
      <Select
        value={teamName}
        onChange={handleChange}
        variant="standard"
        disableUnderline
      >
        {userData.teamNames.map((name) => {
          const lost = name === userData.teamName ? userData.lost : undefined;
          return (
            <MenuItem value={name} key={name}>
              <Typography variant={variant ?? "h4"}>
                <A lost={lost}>{name}</A>
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
      {setDefault ? (
        <Button
          variant="contained"
          disabled={isLoading || teamName === userData.defaultTeamName}
          onClick={handleChangeDefaultTeam}
        >
          Set as Default
        </Button>
      ) : (
        ""
      )}
    </>
  );
}
